/**
 * Shared: Utils > Full screen
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {execute, extend, noop} from "./index";

import EventHandler from '../dom/event-handler';
import Manipulator from "../dom/manipulator";
import Data from "../dom/data";

// -------
// Private
// -------

const NAME      = 'fullscreen';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const DEFAULTS = {
	onEnter: noop,
	onExit : noop
};

const fullScreenActiveControls = new Set();

let fullScreenDocumentEvents = false;

const enterFullScreen = (control) => {
	const options = Data.get(control, 'options');
	const target  = Data.get(control, 'target');

	let flag = false;

	if (target.requestFullscreen) {
		target.requestFullscreen();

		flag = true;
	} else if (target.mozRequestFullScreen) {
		target.mozRequestFullScreen();

		flag = true;
	} else if (target.webkitRequestFullscreen) {
		target.webkitRequestFullscreen();

		flag = true;
	} else if (target.msRequestFullscreen) {
		target.msRequestFullscreen();

		flag = true;
	}

	if (flag) {
		Manipulator.setDataAttribute(target, 'fullscreen-mode', 'true');

		execute(
			options.onEnter,
			control,
			target
		);

		fullScreenActiveControls.add(control);
	}
};

const exitFullScreen = (control) => {
	const options = Data.get(control, 'options');
	const target  = Data.get(control, 'target');

	let flag = false;

	if (document.exitFullscreen) {
		document.exitFullscreen();

		flag = true;
	} else if (document.cancelFullScreen) {
		document.cancelFullScreen();

		flag = true;
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();

		flag = true;
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();

		flag = true;
	}

	if (flag) {
		Manipulator.setDataAttribute(target, 'fullscreen-mode', 'false');

		execute(
			options.onExit,
			control,
			target
		);

		fullScreenActiveControls.delete(control);
	}
};

const escHandler = () => {
	if (
		!document.webkitIsFullScreen &&
		!document.mozFullScreen &&
		!document.msFullscreenElement &&
		fullScreenActiveControls.size > 0
	) {
		for (const control of fullScreenActiveControls) {
			const options = Data.get(control, 'options');
			const target  = Data.get(control, 'target');

			Manipulator.setDataAttribute(target, 'fullscreen-mode', 'false');

			fullScreenActiveControls.delete(control);

			execute(
				options.onExit,
				control,
				target
			);
		}
	}
};

// ------
// Public
// ------

/**
 * Fullscreen initialisieren.
 *
 * @param {HTMLElement} element
 * @param {HTMLElement} target
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const fullScreen = (element, target, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	// Wurden die Doument-Events schon gesetzt?
	if (!fullScreenDocumentEvents) {
		document.addEventListener('fullscreenchange', escHandler);
		document.addEventListener('mozfullscreenchange', escHandler);
		document.addEventListener('webkitfullscreenchange', escHandler);
		document.addEventListener('msfullscreenchange', escHandler);

		fullScreenDocumentEvents = true;
	}

	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	Manipulator.setDataAttribute(target, 'fullscreen-mode', 'false');

	Data.set(element, `options`, _o);
	Data.set(element, `target`, target);

	EventHandler.on(element, `click${EVENT_KEY}`, (event) => {
		event.preventDefault();

		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			enterFullScreen(event.delegateTarget);
		} else {
			exitFullScreen(event.delegateTarget);
		}
	});

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// Export
export {
	fullScreen
};
