/**
 * Prins (Client): Config
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
export default {
	// rte: {
	// 	types : {
	// 		default: {
	// 			toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'blockQuote', '|', 'link', '|', 'insertTable'],
	// 		}
	// 	}
	// }
};
