/**
 * Prins (Client): Components / Rte > Config
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

const core = {};

const plugins = {};

const toolbar = {};

const types = {};

export default {
	core,
	plugins,
	toolbar,
	types
};
