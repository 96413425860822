/**
 * Prins: Components / Form > Auto value
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../../../../shared/dom/selector-engine';
import EventHandler from '../../../../../../shared/dom/event-handler';

import BaseClass from '../../../../../../shared/utils/base-class';

/**
 * @type {string}
 */
const NAME = 'autoValue';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	allowedTypes  : ['email', 'tel', 'text', 'url']// ,
	// onInit        : noop, // (input) => { console.log('onInit', input); },
	// onClear       : noop // (input) => { console.log('onClear', input); }
};

/**
 *  Class
 */
class AutoValue extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = AutoValue.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this.elementType = this._element.getAttribute('type').toLowerCase();
		this.valueFormat = this._element.dataset.formAutovalue;

		if (!this._config.allowedTypes.includes(this.elementType) || !this.valueFormat) {
			return;
		}

		this.observerReadOnly = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (
					mutation.type === 'attributes' &&
					mutation.attributeName === 'readonly' &&
					this._element.readOnly
				) {
					this._updateBySourceFields();
				}
			}
		});

		this._setup();
	}

	/**
	 * API-methode ´clear()´.
	 */
	// clear() {
	// 	this._clear(false);
	// }

	/**
	 * API-methode ´checkStatus()´.
	 */
	checkStatus() {
		if (!this.toggle) {
			return;
		}

		if (this.toggle.checked) {
			this._element.removeAttribute('readonly');
		} else {
			this._element.setAttribute('readonly', 'true');
		}
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		// Überwachung Formualrfeld.
		this.observerReadOnly.observe(this._element, {
			attributes: true
		});

		//
		// Zusammenstellung Source-Eingabefelder.
		//

		this.sourceFields   = [];
		this.valueDelimiter = this._element.dataset.formAutovalueDelimiter;

		const fieldIds = this.valueDelimiter ? this.valueFormat.split(this.valueDelimiter) : [this.valueFormat];

		for (const fieldId of fieldIds) {
			const field = SelectorEngine.findOne(`#${fieldId.trim()}`);

			if (field) {
				EventHandler.on(field, `focus${this.constructor.EVENT_KEY}`, this._updateBySourceFields.bind(this));
				EventHandler.on(field, `keyup${this.constructor.EVENT_KEY}`, this._updateBySourceFields.bind(this));
				EventHandler.on(field, `blur${this.constructor.EVENT_KEY}`, this._updateBySourceFields.bind(this));

				this.sourceFields.push(field);
			}
		}

		//
		// Toggle für das Umschalten von ´read only´ des Ziel-Eingabefeldes initialisieren.
		//

		this.toggle = SelectorEngine.findOne(`#${this._element.getAttribute('id')}-toggle[type="checkbox"]`);

		if (this.toggle) {
			// const checkStatus = (event) => {
			// 	if (event.target.checked) {
			// 		this._element.removeAttribute('readonly');
			// 	} else {
			// 		this._element.setAttribute('readonly', 'true');
			// 	}
			// };

			EventHandler.on(this.toggle, `click${this.constructor.EVENT_KEY}`, this.checkStatus.bind(this));

			// Erster Aufruf.
			this.checkStatus();
		}
	}

	/**
	 * Ziel-Eingabefeld mit akteullem ´value´ versehen.
	 *
	 * @private
	 */
	_updateBySourceFields() {
		const val = this._generatedValue();

		// Attribut ´placeholder´ wird immer aktualisiert.
		this._element.placeholder = val;

		// Attribut ´value´ wird nur aktualisiert, wenn das Ziel-Eingabefeld ´read only´ ist.
		if (this._element.readOnly) {
			this._element.value = val;
		}
	}

	/**
	 * Value für das Ziel-Eingabefeld, Basis ist ´value´ der Source-Eingabefelder, zusammenstellen.
	 *
	 * @returns {string}
	 * @private
	 */
	_generatedValue() {
		let data = [];

		for (const sourceField of this.sourceFields) {
			if (sourceField.value) {
				data.push(sourceField.value);
			}
		}

		return (data.length) ? data.join(this.valueDelimiter) : '';
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default AutoValue;
