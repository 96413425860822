/**
 * Prins (prins-grundsystem-2023)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author heiko
 * @version v2.230726 (2023/07/26 14:59:28, module.js)
 */

/**
 *  Intranet / Layout / Main / Module
 */

import {
	debounce,
	setRootVar
} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';

import Drawer from '../../../../shared/components/drawer';

// -------
// Privat
// -------

const NAME      = 'module';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

let moduleContainer;
let moduleControls;

/**
 * Aktuelle Höhe des Modulkopfes, zwecks ´scroll top etc.´ per Rootvariable bekanntgegeben.
 */
const updateScrollTopOffset = () => {
	const controls = SelectorEngine.findOne('.module__controls', moduleContainer);

	if (controls) {
		const cb = () => {
			setRootVar(
				`${window.Prins.prefix}module-scroll-top-offset`,
				`calc(var(--global-scroll-top-offset) + ${controls.offsetHeight}px)`,
				moduleContainer
			);
		};

		cb();

		window.addEventListener('resize', debounce(() => {
			cb();
		}, 200));
	}
};

/*const filterDrawer = (flag, filterContainer, contentContainer) => {
	let toggle            = null;
	let drawerHasContents = false;

	if (!mfDrawerApi) {
		const target = SelectorEngine.findOne('.module__body', moduleContainer);

		mfDrawerToggle = SelectorEngine.findOne('[data-toggle="module-filter"]', filterContainer);

		if (target && mfDrawerToggle) {
			const ariaLabel       = (mfDrawerToggle.dataset.ariaLabel || mfDrawerToggle.textContent);
			const drawerContainer = Manipulator.elementAppend(
				`<div class="drawer module-filter-drawer" data-drawer="module-filter" data-label="${ariaLabel}" data-placement="start" id="drawer-module-filter">
					<div data-drawer-body></div>
				</div>`,
				target
			);

			EventHandler.on(drawerContainer, 'shown.ifab.drawer', (event) =>  {
				if (!drawerHasContents) {
					const drawerBody = SelectorEngine.findOne('.drwaer__body', event.relatedTarget);
					const childs = SelectorEngine.children(contentContainer, '*');

					if (childs.length) {
						for (const child of childs) {
							Manipulator.elementAppend(child, drawerBody);
						}
					}
				}
			});

			mfDrawerApi = new Drawer(drawerContainer);

			EventHandler.on(mfDrawerToggle, 'click', () => {
				mfDrawerApi.toggle();
			});
		}
	}

	if (flag) {
		// Drawer aktiviert.
		// if (!drawerUsed) {}

		if (mfDrawerApi) {
			mfDrawerToggle.hidden = false;
			contentContainer.hidden = true;

			console.log('Set content in', mfDrawerApi);
		}

		Manipulator.addClass(filterContainer, '-drawer-used');
	} else {
		// Drawer deaktiviert.
		Manipulator.removeClass(filterContainer, '-drawer-used');

		if (mfDrawerApi) {
			mfDrawerToggle.hidden = true;
			contentContainer.hidden = false;

			console.log('Set content in', contentContainer);
		}
	}
};*/

/*const buildFilterDrawer = (filterContainer, filterContent) => {
	let toggle            = null;
	let drawerHasContents = false;

	if (!filterDrawerApi) {
		const appendDrawerTarget = SelectorEngine.findOne('.module__body', moduleContainer);

		toggle = SelectorEngine.findOne('[data-toggle="module-filter"]', filterContainer);

		if (toggle) {
			const ariaLabel       = (toggle.dataset.ariaLabel || toggle.textContent);
			const drawerContainer = Manipulator.elementAppend(
				`<div class="drawer module-filter-drawer" data-drawer="module-filter" data-label="${ariaLabel}" data-placement="start" id="drawer-module-filter">
					<div data-drawer-body></div>
				</div>`,
				appendDrawerTarget
			);

			EventHandler.on(drawerContainer, 'shown.ifab.drawer', (event) =>  {
				if (!drawerHasContents) {
					const drawerBody = SelectorEngine.findOne('.drwaer__body', event.relatedTarget);
					const childs = SelectorEngine.children(contentContainer, '*');

					if (childs.length) {
						for (const child of childs) {
							Manipulator.elementAppend(child, drawerBody);
						}
					}
				}
			});

			filterDrawerApi = new Drawer(drawerContainer);

			EventHandler.on(toggle, 'click', () => {
				filterDrawerApi.toggle();
			});
		}
	}
};*/

/*const setupFilter = () => {
	const mdFilter        = (moduleControls) ? SelectorEngine.findOne('.module-filter', moduleControls) : null;
	const mdFilterContent = (mdFilter) ? SelectorEngine.findOne('.module-filter__content', mdFilter) : null;

	if (!mdFilterContent) {
		return;
	}

	handleFilterForm(SelectorEngine.findOne('.form[data-searched="true"]', mdFilterContent) || null);

	let clientWidthToSmall = 0;

	// Filter inline oder als Drawer anzeigen.
	// Wird der Inhalt zu groß, dann wird zu einem Drawer umgeswitcht.
	const resizeObeserver = new ResizeObserver(debounce(
		(entries) => {
			const cw = mdFilter.clientWidth;
			const sw = mdFilter.scrollWidth;

			if (sw > cw) {
				if (!filterAsDrawer) {
					filterAsDrawer = true;
				}
			} else {
				filterAsDrawer = false;
			}

			// console.log(filterAsDrawer, `CW: ${cw}`, `SW: ${sw}`);

		}, 500)
	);

	resizeObeserver.observe(mdFilter);
};*/

//
// Module filter
//

const filterForm = (form) => {
	if (!form) {
		return;
	}

	// Anpassung ´clearable fields´ in `.form-filter`
	const clearableFields = SelectorEngine.find('[data-clearable]', form);

	if (clearableFields.length > 0) {
		for (const field of clearableFields) {
			EventHandler.on(field, 'clearable.clear', () => {
				setTimeout(() => {
					form.submit();
				}, 100);
			});
		}
	}
};

const filterDrawer = (toggle) => {
	const insertInto = SelectorEngine.findOne('.module__body', moduleContainer);

	if (!insertInto) {
		return null;
	}

	const container = Manipulator.elementAppend(
		`<div class="drawer module-filter-drawer" data-drawer="module-filter" data-label="${toggle.textContent}" data-placement="start" id="drawer-module-filter">
			<div data-drawer-body></div>
		</div>`,
		insertInto
	);

	// EventHandler.on(container, 'shown.ifab.drawer', (event) =>  {});

	EventHandler.on(container, 'hidden.ifab.drawer', () =>  {
		EventHandler.trigger(toggle, `off${EVENT_KEY}`);
	});

	return new Drawer(container);
};

const setupFilter = () => {
	const mdFilter       = SelectorEngine.findOne('.module-filter', moduleControls);
	const mdFilterToggle = (mdFilter) ? SelectorEngine.findOne('[data-toggle="module-filter"]', mdFilter) : null;
	const mdFilterForm   = (mdFilter) ? SelectorEngine.findOne('.form-module-filter', mdFilter) : null;

	let currentLayout = 'default';

	if (mdFilter) {
		// Filtercontainer: Standardlayoutklasse zuweisen.
		Manipulator.addClass(mdFilter, '-default');

		// Abbruch, wenn kein Toggle und/oder kein etnspr. Formular vorhanden ist.
		if (!mdFilterToggle || !mdFilterForm) {
			if (mdFilterToggle) {
				mdFilterToggle.remove();
			}

			return;
		}
	} else {
		// Abbruch, wenn Filtercontainer nicht vorhanden ist.
		return;
	}

	// Filterformular behandeln (Aktionen etc.).
	filterForm(mdFilterForm);

	// Drawer initialisieren & Layouthandling anbinden.
	const drawer = filterDrawer(mdFilterToggle);

	if (drawer === null) {
		// Toggle entfernen, wenn es keinen Drawer gibt.
		mdFilterToggle.remove();
	} else {
		//
		// Filter container
		//

		// ... Event ´switch layout´.
		EventHandler.on(mdFilter, `filterCustom${EVENT_KEY}`, (event) => {
			// Toggle ´einblenden´.
			mdFilterToggle.hidden = false;

			// Durchzuführende Aktionen, wenn sich das Layout ändert.
			if (!currentLayout || currentLayout !== 'custom') {
				currentLayout = 'custom';

				// Filterinhaltscontainer in Drawer-Body setzen.
				Manipulator.elementAppend(mdFilterForm, drawer.elements.body);
			}

			// CSS-Klassen umswitchen.
			Manipulator.addClass(event.target, '-custom');
			Manipulator.removeClass(event.target, '-default');
		});

		// ... Event ´reset layout´.
		EventHandler.on(mdFilter, `filterReset${EVENT_KEY}`, (event) => {
			// Durchzuführende Aktionen, wenn sich das Layout ändert.
			if (!currentLayout || currentLayout !== 'default') {
				currentLayout = 'default';

				// Drawer ausblenden.
				drawer.hide();

				// Filterinhaltscontainer in Filtercontainer setzen.
				Manipulator.elementAppend(mdFilterForm, mdFilter);
			}

			// Toggle ´ausschalten & -blenden´.
			mdFilterToggle.hidden = true;
			EventHandler.trigger(mdFilterToggle, `off${EVENT_KEY}`);

			// CSS-Klassen umswitchen.
			Manipulator.addClass(event.target, '-default');
			Manipulator.removeClass(event.target, '-custom');
		});

		//
		// Toggle
		//

		// ... Standards setzen.
		Manipulator.setAria(mdFilterToggle, 'pressed', false);
		Manipulator.removeClass(mdFilterToggle, '_active');
		mdFilterToggle.hidden = true;

		// ... Event ´on´.
		EventHandler.on(mdFilterToggle, `on${EVENT_KEY}`, (event) => {
			Manipulator.setAria(event.target, 'pressed', true);
			Manipulator.addClass(event.target, '_active');

			drawer.show();
		});
		// ... Event ´off´.
		EventHandler.on(mdFilterToggle, `off${EVENT_KEY}`, (event) => {
			Manipulator.setAria(event.target, 'pressed', false);
			Manipulator.removeClass(event.target, '_active');

			drawer.hide();
		});
		// ... Event ´click´.
		EventHandler.on(mdFilterToggle, `click${EVENT_KEY}`, (event) => {
			const evName = Manipulator.getAria(event.target, 'pressed') === true ? 'off' : 'on';

			event.preventDefault();

			EventHandler.trigger(event.target, `${evName}${EVENT_KEY}`);
		});

		//
		// Prüfung Filterlayout anbinden.
		//

		const checkFilterLayout = () => {
			const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			if (screenWidth < window.Prins.config.breakpointModuleFilter) {
				EventHandler.trigger(mdFilter, `filterCustom${EVENT_KEY}`);
			} else {
				EventHandler.trigger(mdFilter, `filterReset${EVENT_KEY}`);
			}
		};

		checkFilterLayout();
		window.addEventListener('resize', debounce(checkFilterLayout));
	}
};

// ------
// Public
// ------

const module = () => {
	moduleContainer = SelectorEngine.findOne('#app-main .module') || null;
	moduleControls  = (moduleContainer) ? SelectorEngine.findOne('.module__controls', moduleContainer) : null;

	if (!moduleContainer) {
		return null;
	}

	updateScrollTopOffset();

	if (moduleControls) {
		setupFilter();
	}

	return {
		moduleContainer,
		moduleControls
	};
};

// Export
export default module;
