/**
 * Prins (prins-grundsystem-2023)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author heiko
 * @version v2.230726 (2023/07/26 14:47:19, header.js)
 */

/**
 *  Intranet / Layout / Header
 */

import {
	debounce,
	setRootVar
} from '../../../shared/utils/index';
import {fullScreen} from "../../../shared/utils/full-screen";
import {observeInView} from '../../../shared/utils/observer';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Privat
// -------

let headerContainer = null;

/**
 * Aktuelle Höhe des Seitenkopfes, zwecks ´scroll top etc.´ per Rootvariable bekanntgegeben.
 */
const updateGlobalScrollTopOffset = () => {
	setRootVar('global-scroll-top-offset', `${headerContainer.offsetHeight}px`);
};

/**
 * Erkenne, wann der Seitenkopf "real" sticky wird.
 * Dazu wird die Hilfe eines ´Sentinel-Elementes´ benötigt. Dieses Element wird
 * überwacht (Viewport verlassen) und entspr. Events werden ausgeführt (Switch
 * von CSS-Klassen bspw.).
 */
const observeHeader = () => {
	const compStyle = window.getComputedStyle(headerContainer);
	const cssPos    = compStyle.getPropertyValue('position');

	if (cssPos === 'sticky' || cssPos === 'fixed') {
		// Wurde der Seitenkopf als sticky per CSS deklariert, dann muss
		// eine entsprechende Überwachung des "realen" sticky-Status
		// angebunden werden.

		// Sentinel generieren.
		const sentinel       = Manipulator.elementBefore('<div class="app-header-sentinel"/>', headerContainer);
		const sentinelTarget = document.documentElement;

		// Observer initialisieren.
		observeInView(sentinel, {
			onEnterViewport: () => {
				Manipulator.removeClass(sentinelTarget, 'app-header-is-sticky');

				updateGlobalScrollTopOffset();
			},
			onLeaveViewport: () => {
				Manipulator.addClass(sentinelTarget, 'app-header-is-sticky');

				updateGlobalScrollTopOffset();
			},
			observer       : {
				threshold: 0
			}
		});
	}

	updateGlobalScrollTopOffset();

	window.addEventListener('resize', debounce(() => {
		updateGlobalScrollTopOffset();
	}, 200));
};

const controlFullSize = () => {
	const control = SelectorEngine.findOne('[data-toggle="fullscreen-document"]', headerContainer);

	if (control) {
		Manipulator.setAria(control, 'pressed', 'false');

		fullScreen(control, document.documentElement, {
			onEnter: (control) => {
				Manipulator.setAria(control, 'pressed', 'true');
				Manipulator.addClass(control, '_active');
			},
			onExit : (control) => {
				Manipulator.setAria(control, 'pressed', 'false');
				Manipulator.removeClass(control, '_active');
			}
		});
	}
};

// -------
// Public
// -------

const header = () => {
	headerContainer = headerContainer || SelectorEngine.findOne('#app-header');

	if (headerContainer) {
		observeHeader();

		controlFullSize();
	}

	return(headerContainer);
};

// Export
export default header;
