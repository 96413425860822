/**
 * Shared: Components > Drawer
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	executeAfterTransition,
	extend,
	getUid,
	noop,
	triggerReflow
} from '../../utils';
import {isElement} from '../../utils/is';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../utils/scroll';
import {focusVisible}  from '../../utils/focus-visible';
import {getTabbableElements} from '../../utils/tabbable';
import FocusTrap from '../../utils/focustrap';

import Data           from '../../dom/data';
import Manipulator    from '../../dom/manipulator';
import SelectorEngine from '../../dom/selector-engine';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME       = 'drawer';
const DATA_KEY   = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
const API_KEY    = `${DATA_KEY}.data-api`;
const ESCAPE_KEY = 'Escape';

const PLACEMENT_ALLOWED = ['top', 'bottom', 'start', 'end'];
const PLACEMENT_DEFAULT = 'end';

// -------
// Public
// -------

/**
 * Drawer
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {HTMLElement}
 * @returns {Object|null}
 * @constructor
 */
function Drawer(element, o = {}) {
	if (!isElement(element)) {
		return null;
	}

	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return Data.get(element, API_KEY);
	}

	// Aktuelle Einstellungen.
	this.options = extend({}, Drawer.DEFAULTS, o);

	// Container
	this._container        = element;
	this._container.hidden = true;
	this._container.setAttribute('tabindex', '-1');

	// Aktueller Status.
	this._isContained     = Manipulator.hasDataAttribute(this._container, 'contained') || this.options.contained;
	this._isOpen          = false;
	this._isTransitioning = false;

	// Aktueller Trigger.
	// Aktuelles fokussiertes Element (vor dem Öffnen des Drawer) speichern,
	// damit nach dem Schließen der Drawer der Fokus wieder gesetzt werden kann.
	this._originalTrigger = null;

	// Aktuell per Tab ausgewähltes Element und aktuelle Tab-Richtung.
	// this._currentTabElement   = null;
	// this._currentTabDirection = 'forward';

	// Position/Ausrichtung prüfen.
	let placement = Manipulator.getDataAttribute(this._container, 'placement') || this.options.placement;

	if (!PLACEMENT_ALLOWED.includes(placement)) {
		this.options.placement = placement = PLACEMENT_DEFAULT;
	}

	Manipulator.setDataAttribute(this._container, 'placement', placement);

	// Rendering (Container, Events, ...)
	this._render();

	// API-Zugriffsmöglichkiten
	const api = {
		hide    : this.hide.bind(this),
		show    : this.show.bind(this),
		toggle  : this.toggle.bind(this),
		elements: {
			container: this._container,
			body     : this._body,
			header   : this._header,
			footer   : this._footer
		}
	};

	// API im Container verfügbar machen
	Data.set(this._container, API_KEY, api);

	// Initialisierungsstatus setzen.
	Data.set(this._container,`${DATA_KEY}.initialized`, true);

	return api;
}

/**
 * Standardoptionen.
 *
 * @constant {Object}
 */
Drawer.DEFAULTS = {
	focus       : true,
	label       : '',
	closeButton : true,
	closeLabel  : 'close',
	closeIcon   : '<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 6 18 18"/><polyline points="6 18 18 6"/></svg>',
	placement   : PLACEMENT_DEFAULT,
	closeByEsc  : true,
	setFocusBack: true,
	preventClose: false,
	noHeader    : false,
	contained   : false,
	onInit      : noop,
	onHide      : noop,
	onHidden    : noop,
	onShow      : noop,
	onShown     : noop
};

Drawer.prototype.show = function() {
	if (this._isOpen || this._isTransitioning) {
		return;
	}

	this._originalTrigger = document.activeElement;

	const relatedTarget = this._panel;
	const eventShow     = EventHandler.trigger(this._container, `show${EVENT_KEY}`, {
		relatedTarget,
		trigger: this._originalTrigger
	});

	execute(
		this.options.onShow,
		eventShow
	);

	if (eventShow.defaultPrevented) {
		return;
	}

	this._isOpen          = true;
	this._isTransitioning = true;

	this._container.hidden = false;

	// Wenn der Drawer (Panel) angezeigt wird, versucht Safari automatisch auf
	// das erste Element mit dem Attribut `autofocus` den Focus zu setzen.
	// Dies kann zu unschönen visuellen Erscheinungen führen. Von daher wird
	// `autofocus` entfernt und nach dem Öffnen des Drawer wieder reintegriert.
	const elementAutoFocus = SelectorEngine.findOne('[autofocus]', this._container);

	if (elementAutoFocus) {
		elementAutoFocus.removeAttribute('autofocus');
	}

	if (!this._isContained) {
		// document.addEventListener('focusin', drawerHandleFocusIn);
		// document.addEventListener('keydown', drawerHandleKeyDown);
		// document.addEventListener('keyup', drawerHandleKeyUp);
		// document.addEventListener('focusin', this._handleFocusIn.bind(this));
		// document.addEventListener('keydown', this._handleKeyDown.bind(this));
		// document.addEventListener('keyup', this._handleKeyUp.bind(this));

		// Scrollen des Dokumentes sperren.
		lockBodyScrolling(this._container);
	}

	triggerReflow(this._container);

	Manipulator.addClass(this._container, '-open');
	Manipulator.setAria(this._panel, 'hidden', 'false');

	// Ist der Drawer nicht ´inline´, dann aktiviere:
	// - Fokusfalle (per Tab nur im Panelnavigieren)
	// - Schließen per ´Escape´
	if (!this._isContained) {
		this._focustrap.activate();

		if (this.options.closeByEsc) {
			EventHandler.on(this._container, `keydown.dismis${EVENT_KEY}`, (event) => {
				if (event.key !== ESCAPE_KEY) {
					return;
				}

				this.hide();
			});
		}
	}

	executeAfterTransition(
		() => {
			this._isTransitioning = false;

			const eventShown = EventHandler.trigger(this._container, `shown${EVENT_KEY}`, {
				relatedTarget,
				trigger: this._originalTrigger
			});

			// Attribute `autofocus` reintegrieren.
			if (elementAutoFocus) {
				elementAutoFocus.setAttribute('autofocus', '');
			}

			if (this.options.focus) {
				const tabbableElements = getTabbableElements(this._panel);

				if (tabbableElements.length > 0) {
					tabbableElements[0].focus({preventScroll: true});
				}
			}

			execute(
				this.options.onShown,
				eventShown
			);
		},
		this._panel
	);
};

Drawer.prototype.hide = function() {
	if (!this._isOpen || this._isTransitioning) {
		return;
	}

	// Ist der Drawer nicht ´inline´, dann deaktiviere:
	// - Fokusfalle (per Tab nur im Panelnavigieren)
	// - Schließen per ´Escape´
	if (!this._isContained) {
		this._focustrap.deactivate();

		// Event ´ESC´ entfernen.
		if (this.options.closeByEsc) {
			EventHandler.off(this._container, `keydown.dismis${EVENT_KEY}`);
		}
	}

	const relatedTarget = this._panel;
	const eventHide     = EventHandler.trigger(this._container, `hide${EVENT_KEY}`, {
		relatedTarget,
		trigger: this._originalTrigger
	});

	execute(
		this.options.onHide,
		eventHide
	);

	if (eventHide.defaultPrevented) {
		return;
	}

	this._isOpen          = false;
	this._isTransitioning = true;
	// Aktuell per Tab ausgewähltes Element zurücksetzen.
	this._currentTabElement   = null;

	Manipulator.removeClass(this._container, '-open');

	executeAfterTransition(
		() => {
			this._isTransitioning = false;

			this._container.hidden = true;

			Manipulator.setAria(this._panel, 'hidden', 'true');

			const eventHidden = EventHandler.trigger(this._container, `hidden${EVENT_KEY}`, {
				relatedTarget,
				trigger: this._originalTrigger
			});

			execute(
				this.options.onHidden,
				eventHidden
			);

			// Scrollen des Dokumentes entsperren.
			unlockBodyScrolling(this._container);

			this._setFocusBack();

			this._originalTrigger = null;
		},
		this._panel
	);
};

Drawer.prototype.toggle = function() {
	if (!this._isTransitioning) {
		if (this._isOpen) {
			this.hide();
		} else {
			this.show();
		}
	}
};

Drawer.prototype._render = function() {
	if (this._isContained) {
		Manipulator.addClass(this._container, '-contained');

		const parent = this._container.parentNode;

		if (parent) {
			Manipulator.addClass(parent, 'contains-drawer');
		}
	}

	this._overlay = Manipulator.elementAppend(
		`<div class="${NAME}__overlay" tabindex="-1"/>`,
		this._container
	);

	this._btnClose = Manipulator.createElementFrom(`
		<button aria-label="${this.options.closeLabel}" class="icon-button ${NAME}-close" type="button">
			<span aria-hidden="true" class="icon-button__icon">${this.options.closeIcon}</span>
		</button>
	`);

	this._panel = Manipulator.elementAppend(
		`<div
			aria-hidden="true"
			aria-modal="true"
			class="${NAME}__panel"
			role="dialog"
			tabindex="-1"
		/>`,
		this._container
	);

	this._focustrap = new FocusTrap(this._panel);

	this._buildHeader();
	this._buildBody();
	this._buildFooter();
	this._addListeners();

	Manipulator.addClass(this._container, '-initialized');

	const relatedTarget = this._panel;

	const eventInit = EventHandler.trigger(this._container, `init${EVENT_KEY}`, {
		relatedTarget
	});

	execute(
		this.options.onInit,
		eventInit
	);
};

Drawer.prototype._buildHeader = function() {
	const id = getUid(`${NAME}-header`);

	if (this.options.noHeader) {
		this._header = null;

		// Bezgl. ´Accessibility´ ist ein Attribut `aria-label` nötig!
		if (this.options.label) {
			Manipulator.setAria(this._panel, 'label', this.options.label);
		}
	} else {
		this._header = Manipulator.createElementFrom(`<header class="${NAME}__header"/>`);

		const tplHeader = SelectorEngine.findOne(`[data-${NAME}-header]`, this._container);

		if (tplHeader) {
			this._copyContent(tplHeader, this._header);

			const customTitle = SelectorEngine.findOne(`${NAME}-title,.title,h1,h2,h3,h4,h5,h6`, this._header);

			if (customTitle) {
				customTitle.setAttribute('id', id);

				Manipulator.setAria(this._panel, 'labelledby', id);
			}

			if (this.options.closeButton) {
				Manipulator.elementAppend(this._btnClose, this._header);
			}

			Manipulator.elementAppend(this._header, this._panel);

			focusVisible.observe(this._btnClose);

			tplHeader.remove();
		} else {
			const label = Manipulator.getDataAttribute(this._container, 'label') || this.options.label;

			if (label) {
				Manipulator.setAria(this._panel, 'labelledby', id);

				Manipulator.elementPrepend(
					`<div aria-level="2" class="${NAME}-title" id="${id}" role="heading">${label}</div>`,
					this._header
				);

				if (this.options.closeButton) {
					Manipulator.elementAppend(this._btnClose, this._header);
				}

				Manipulator.elementAppend(this._header, this._panel);

				focusVisible.observe(this._btnClose);
			} else {
				this._header = null;
			}
		}
	}
};

Drawer.prototype._buildBody = function() {
	this._body = Manipulator.createElementFrom(`<div class="${NAME}__body"/>`);

	const tplBody = SelectorEngine.findOne(`[data-${NAME}-body]`, this._container);

	if (tplBody) {
		this._copyContent(tplBody, this._body);

		tplBody.remove();
	}

	Manipulator.elementAppend(this._body, this._panel);
};

Drawer.prototype._buildFooter = function() {
	const tplFooter = SelectorEngine.findOne(`[data-${NAME}-footer]`, this._container);

	if (tplFooter) {
		this._footer = Manipulator.createElementFrom(`<footer class="${NAME}__footer"/>`);

		this._copyContent(tplFooter, this._footer);

		Manipulator.elementAppend(this._footer, this._panel);

		tplFooter.remove();
	} else {
		this._footer = null;
	}
};

Drawer.prototype._addListeners = function() {
	EventHandler.on(this._panel, `click${EVENT_KEY}`, `.${NAME}-close`, event => {
		event.preventDefault();

		if (this._isOpen) {
			this.hide();
		}
	});

	EventHandler.on(this._container, `click${EVENT_KEY}`, `.${NAME}__overlay`, event => {
		event.preventDefault();
		event.stopPropagation();

		if (!this.options.preventClose && this._isOpen) {
			this.hide();
		}
	});
};

// Focus auf originalen Trigger ´zurücksetzen´.
Drawer.prototype._setFocusBack = function() {
	if (this.options.setFocusBack) {
		const trigger = this._originalTrigger;

		if (trigger && typeof trigger.focus === 'function') {
			setTimeout(() => trigger.focus());
		}
	}
};

Drawer.prototype._copyContent = function(from, to) {
	const elements = SelectorEngine.children(from, '*');

	if (elements) {
		for (const element of elements) {
			Manipulator.elementAppend(element, to);
		}
	}
};

// Export
export default Drawer;
