/**
 * Prins: Components / Nested record
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from "../../../../shared/dom/selector-engine";
import EventHandler from "../../../../shared/dom/event-handler";
import Manipulator from "../../../../shared/dom/manipulator";
import Data from "../../../../shared/dom/data";

import Modal from '../modal';
import Spinner from '../../../../shared/components/spinner';
//
// import Reveal from '../../../../shared/components/reveal';
// import {execute} from "../../../../shared/utils";

// -------
// Private
// -------

const NAME      = 'nested-records';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

/**
 * @param {HTMLElement} container
 * @returns {HTMLElement}
 * @returns {*}
 */
const render = (container) => {
	const form = SelectorEngine.parents(container, '.form')[0] ?? null;
	const groupContainer = SelectorEngine.findOne('[data-records]', container);
	const editId = container.dataset.editId ?? null;
	const isSortable = groupContainer.dataset.sortable ?? null;

	const xhrLoad = container.dataset.xhrLoad ?? null;
	const xhrSortable = container.dataset.xhrSortable ?? null;

	if (!xhrLoad || !window.axios) {
		return;
	}

	// Attribut `data-xhr-load` entfernen um Manipulationen vorzubeugen.
	if (xhrLoad) {
		delete container.dataset.xhrLoad;
	}

	// Attribut `data-xhr-sortable` entfernen um Manipulationen vorzubeugen.
	if (xhrSortable) {
		delete container.dataset.xhrSortable;
	}

	//
	// Container vorbereiten
	//

	Manipulator.setAria(container, 'live', 'polite');
	Manipulator.setAria(container, 'busy', false);

	EventHandler.on(container, `load.${EVENT_KEY}`, (event) => {
		Manipulator.setAria(container, 'busy', true);

		Spinner.addTo(container);

		window.axios
			.post(xhrLoad, {
				 responseType: 'document'
			})
			.then(function (response) {
				groupContainer.textContent = '';

				if (response.data) {
					groupContainer.innerHTML = response.data;

					parseControls(groupContainer);
				}

				Spinner.removeFrom(container);
				Manipulator.setAria(container, 'busy', false);

				if (form) {
					EventHandler.trigger(form, 'changeDetected', {
						changeDetectedOn : container
					});
				}
			})
			.catch(function (error) {
				throw new Error(error);
			});
	});

	EventHandler.on(groupContainer, 'sorted.ifab.sortable', (event) => {
		if (xhrSortable) {
			window.axios
				.post(xhrSortable, {
					sorting: event.sortableData,
					editId : editId
				}, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
				.then(function (response) {
					if (response.data.status) {
						EventHandler.trigger(container, `load.${EVENT_KEY}`);
					}
				})
				.catch(function (error) {
					throw new Error(error);
				});
		}
	});

	/**
	 * Trigger initialisieren
	 *
	 * @param {HTMLElement} container
	 */
	const parseControls = (container) => {
		const editTriggers = SelectorEngine.find('button[data-add], button[data-edit]', container);

		for (const trigger of editTriggers) {
			EventHandler.on(trigger, 'closed.ifab.modal', (event) => {
				EventHandler.trigger(container, `load.${EVENT_KEY}`);
			});

			Modal.bindTriggerIframe(trigger);
		}

		const removeTriggers = SelectorEngine.find('button[data-remove]', container);

		for (const trigger of removeTriggers) {
			if (trigger.dataset.xhr && trigger.dataset.xhr !== '') {
				// XHR-URI in den Elementdaten speichern & Attribut entfernen (Vorbeugung Manipulation)
				Data.set(trigger, 'xhr', trigger.dataset.xhr);

				delete trigger.dataset.xhr;

				EventHandler.on(trigger, 'confirmed.ifab.modal', (event) => {
					window.axios
						.post(Data.get(trigger, 'xhr'), {})
						.then(function (response) {
							if (response.data.status) {
								EventHandler.trigger(container, `load.${EVENT_KEY}`);
							}
						})
						.catch(function (error) {
							throw new Error(error);
						});
				});

				Modal.bindTriggerDelete(trigger);
			} else {
				Manipulator.setDisabled(trigger);
			}
		}
	};

	//
	// Controls initialisieren
	//

	parseControls(container);
};

// ------
// Public
// ------

/**
 * ´Nested record´-Elemente zusammenstellen und initialisieren.
 */
const init = () => {
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {
		render(element);
	}
};

// Export
export default {
	init: init
};
