/**
 * Shared: Utils > String
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// -------
// Private
// -------


// ------
// Public
// ------

/**
 * Geschützte und normale meehrfache leerzeichen entfernen.
 *
 * @param {string} s
  * @returns {string}
 */
const normalizeWhitespaces = (s) => {
	// Geschützte leerezeichen durch ein normales Leerzeichen ersetzen.
	let r = s.replace(/&nbsp;/g, '');

	r = r.replace(/&amp;nbsp;/g, '');

	// Mehrere leerezeichen durch ein Leerzeichen ersetzen.
	r = r.replace(/\s\s+/g, ' ');

	// Entferne Leerzeichen zwischen einem schließendem `>` und öffnendem `<`.
	r = r.replace(/> <+/g, '><');

	return r;
};

/**
 * Entfernen von P-Tags.
 *
 * @param {string} s
 * @param {string} r
 * @returns {string}
 */
const removeParagraphs = (s, r = '<br/>') => {
	return s;
};

/**
 * Prüfe auf ´array´.
 *
 * @param {string} s
 * @param {Number} l
 * @param {string} add
 * @returns {string}
 */
const truncate = (s, l = 180, add = '...') => {
	let sNew = stripTags(s);

	const sLength = sNew.length;


	if ((sLength + add.length) > l) {
		sNew = sNew.slice(0, (l - add.length)) + add;
	}

	return sNew;
};

const stripTags = (s) => {
	// Tags entfernen.
	s = s.replace( /(<([^>]+)>)/gi, ' ');

	// Multiple Leerzeichen entfernen.
	s = s.replace(/  +/g, ' ');

	// Leerzeichen mit aufeinanderfolgendem Satzzeichen ersetzen
	s = s.replace(/ \.+/g, '.');
	s = s.replace(/ !+/g, '!');
	s = s.replace(/ \?+/g, '?');

	return s;
};

/**
 * @param {string} s
 * @param {string} joinBy
 * @returns {string}
 */
const lowerCamelCase = (s, joinBy = '') => {
	return s
		.toLowerCase()
		.replace('-', ' ')
		.replace('_', ' ')
		.replace('.', '')
		.split(' ')
		.map(word => word.charAt(0).toLowerCase() + word.slice(1))
		.join(joinBy);
};

/**
 * @param {string} s
 * @param {string} joinBy
 * @returns {string}
 */
const upperCamelCase = (s, joinBy = '') => {
	return s
		.toLowerCase()
		.replace('-', ' ')
		.replace('_', ' ')
		.replace('.', '')
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(joinBy);
};

// Export
export {
	lowerCamelCase,
	normalizeWhitespaces,
	removeParagraphs,
	stripTags,
	truncate,
	upperCamelCase,
};
