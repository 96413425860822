/**
 * Prins: Components / Modal
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend, noop} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'notifications';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const toastShow = (text, options = {}) => {
	if (text) {
		let avatar = '';

		if (options.avatar) {
			avatar = `<div class="toastify__icon">${options.avatar}</div>`;
		}

		const container = Manipulator.createElementFrom(`
			<div class="toastify-container">${avatar}<div class="toastify__message">${text}</div></div>
		`);

		Toastify(extend({
			className  : '-fill',
			gravity    : 'top',
			node       : container,
			offset     : {
				// x: '0.5rem',
				y: '-0.5rem'
			},
			oldestFirst: false,
			position   : 'right'
		}, options, {
			avatar: ''
		})).showToast();
	}
};

const toastDanger = (text = '', options = {}) => {
	const _o = extend({}, {
		avatar   : window.Prins.config.icons.danger,
		className: '-danger -fill',
		close    : true,
		duration : 5000
	}, options);

	toastShow(text, _o);
};

const toastInfo = (text = '', options = {}) => {
	const _o = extend({}, {
		avatar   : window.Prins.config.icons.info,
		className: '-info -fill',
		close    : true,
		duration : 5000
	}, options);

	toastShow(text, _o);
};

const toastSuccess = (text = '', options = {}) => {
	const _o = extend({}, {
		avatar   : window.Prins.config.icons.success,
		className: '-success -fill',
		close    : true,
		duration : 5000
	}, options);

	toastShow(text, _o);
};

const toastWarning = (text = '', options = {}) => {
	const _o = extend({}, {
		avatar   : window.Prins.config.icons.warning,
		className: '-warning -fill',
		close    : true,
		duration : 5000
	}, options);

	toastShow(text, _o);
};

const renderFlashNotifications = () => {
	const flashCollection = SelectorEngine.find('[data-flash-notification]');
	const wait            = 550;

	let index = 1;

	if (flashCollection.length) {
		for (const element of flashCollection) {
			const text = element.textContent;

			if (text) {
				const type = element.dataset.type ?? '';

				switch (type) {
					case 'info': {
						setTimeout(function() {
							window.Prins.notificationInfo(text);
						}, (index * wait));
						break;
					}

					case 'danger': {
						setTimeout(function() {
							window.Prins.notificationDanger(text);
						}, (index * wait));
						break;
					}

					case 'success': {
						setTimeout(function() {
							window.Prins.notificationSuccess(text);
						}, (index * wait));
						break;
					}

					case 'warning': {
						setTimeout(function() {
							window.Prins.notificationWarning(text);
						}, (index * wait));
						break;
					}

					default: {
						setTimeout(function() {
							window.Prins.notification(text);
						}, (index * 50));
						break;
					}
				}

				index++;
			}
		}
	}
};

// ------
// Public
// ------

/**
 * ´Notification´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	window.Prins.notification        = (window.Toastify === undefined) ? noop : toastShow;
	window.Prins.notificationInfo    = (window.Toastify === undefined) ? noop : toastInfo;
	window.Prins.notificationDanger  = (window.Toastify === undefined) ? noop : toastDanger;
	window.Prins.notificationSuccess = (window.Toastify === undefined) ? noop : toastSuccess;
	window.Prins.notificationWarning = (window.Toastify === undefined) ? noop : toastWarning;

	renderFlashNotifications();

	// window.Prins.notification('Default lorem ipsum dolor sit amed', {
	// 	duration : -1
	// });
	// window.Prins.notificationInfo('Info lorem ipsum dolor sit amed', {
	// 	duration : -1
	// });
	// window.Prins.notificationDanger('Danger lorem ipsum dolor sit amed', {
	// 	duration : -1
	// });
	// window.Prins.notificationSuccess('Success lorem ipsum dolor sit amed', {
	// 	duration : -1
	// });
	// window.Prins.notificationWarning('Warning lorem ipsum dolor sit amed', {
	// 	duration : -1
	// });
};

// Export
export default {
	init: init
};
