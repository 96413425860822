/**
 * Prins: Components / App preloader
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {noop} from '../../../../shared/utils';

const preloaderElement = document.getElementById('app-preloader');

const show = (cb = noop, delay = 50) => {
	if (preloaderElement) {
		setTimeout(() => {
			document.documentElement.dataset.preloader = 'enabled';

			cb();
		}, delay);
	} else {
		cb();
	}
};

const hide = (cb = noop, delay = 150) => {
	if (preloaderElement) {
		setTimeout(() => {
			document.documentElement.dataset.preloader = 'disabled';

			cb();
		}, delay);
	} else {
		cb();
	}
};

// --------
// Let's go
// --------
export default {
	show,
	hide
};
