/**
 * Prins: Components / Rte / Config / Core
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */
import {executeAfterTransition} from "../../../../../shared/utils";
import Manipulator from "../../../../../shared/dom/manipulator";

const availableReplacements = {
	section   : {
		rx       : '<section[^>]*>(.*?)<\/section>',
		replaceBy: '$1'
	},
	div       : {
		rx       : '<div[^>]*>(.*?)<\/div>',
		replaceBy: '$1'
	},
	article   : {
		rx       : '<article[^>]*>(.*?)<\/article>',
		replaceBy: '$1'
	},
	header    : {
		rx       : '<header[^>]*>(.*?)<\/header>',
		replaceBy: '$1'
	},
	paragraph    : {
		rx       : '<p[^>]*>(.*?)<\/p>',
		replaceBy: '$1'
	},
	template  : {
		rx       : '<template[^>]*>(.*?)<\/template>',
		replaceBy: '$1'
	},
	h1        : {
		rx       : '<h1[^>]*>(.*?)<\/h1>',
		replaceBy: '<p>$1</p>'
	},
	headings  : {
		rx       : '<h[1-6][^>]*>(.*?)<\/h[1-6]>',
		replaceBy: '<p>$1</p>'
	},
	anchor    : {
		rx       : '<a[^>]*>(.*?)<\/a>',
		replaceBy: '$1'
	},
	code      : {
		rx       : '<code[^>]*>(.*?)<\/code>',
		replaceBy: '<span>$1</span>'
	},
	cite      : {
		rx       : '<cite[^>]*>(.*?)<\/cite>',
		replaceBy: '<span>$1</span>'
	},
	blockquote: {
		rx       : '<blockquote[^>]*>(.*?)<\/blockquote>',
		replaceBy: '<p>$1</p>'
	},
	image: {
		rx       : '<img[^>]*>',
		replaceBy: ''
	},
	svg: {
		rx       : '<svg[^>]*>(.*?)<\/svg>',
		replaceBy: ''
	},
	dd        : {
		rx       : '<dd[^>]*>(.*?)<\/dd>',
		replaceBy: '<p>$1</p>'
	},
	dt        : {
		rx       : '<dt[^>]*>(.*?)<\\/dt>',
		replaceBy: '<p><strong>$1</strong></p>'
	},
	dl        : {
		rx       : '<dl[^>]*>(.*?)<\\/dl>',
		replaceBy: '$1'
	},
	li        : {
		rx       : '<li[^>]*>(.*?)<\/li>',
		replaceBy: '<p>$1</p>'
	},
	ul        : {
		rx       : '<ul[^>]*>(.*?)<\\/ul>',
		replaceBy: '$1'
	},
	ol        : {
		rx       : '<ol[^>]*>(.*?)<\\/ol>',
		replaceBy: '$1'
	}
};

const cleanupTypes = {
	default: {
		allowPaste     : 'text/html', // 'text/plain', null
		useReplacements: [
			availableReplacements.section,
			availableReplacements.article,
			availableReplacements.header,
			availableReplacements.template,
			availableReplacements.div,
			availableReplacements.h1,
			availableReplacements.code,
			availableReplacements.cite,
			availableReplacements.blockquote,
			availableReplacements.image,
			availableReplacements.svg,
			availableReplacements.dd,
			availableReplacements.dt,
			availableReplacements.dl
		]
	},
	full   : {
		allowPaste     : 'text/html', // 'text/plain', null
		useReplacements: [
			availableReplacements.section,
			availableReplacements.article,
			availableReplacements.header,
			availableReplacements.template,
			availableReplacements.div,
			availableReplacements.h1,
			availableReplacements.code,
			availableReplacements.cite,
			availableReplacements.blockquote,
			availableReplacements.image,
			availableReplacements.svg,
			availableReplacements.dd,
			availableReplacements.dt,
			availableReplacements.dl
		]
	},
	simple : {
		allowPaste     : 'text/html', // 'text/plain', null
		useReplacements: [
			availableReplacements.section,
			availableReplacements.div,
			availableReplacements.article,
			availableReplacements.header,
			availableReplacements.template,
			availableReplacements.h1,
			availableReplacements.headings,
			availableReplacements.anchor,
			availableReplacements.code,
			availableReplacements.cite,
			availableReplacements.blockquote,
			availableReplacements.image,
			availableReplacements.svg,
			availableReplacements.dd,
			availableReplacements.dt,
			availableReplacements.dl,
			availableReplacements.li,
			availableReplacements.ul,
			availableReplacements.ol
		]
	},
	title  : {
		allowPaste     : 'text/html', // 'text/plain', null
		useReplacements: [
			availableReplacements.section,
			availableReplacements.div,
			availableReplacements.article,
			availableReplacements.header,
			availableReplacements.template,
			availableReplacements.headings,
			availableReplacements.anchor,
			availableReplacements.code,
			availableReplacements.cite,
			availableReplacements.blockquote,
			availableReplacements.image,
			availableReplacements.svg,
			availableReplacements.dd,
			availableReplacements.dt,
			availableReplacements.dl,
			availableReplacements.li,
			availableReplacements.ul,
			availableReplacements.ol,
			availableReplacements.paragraph
		]
	}
};

//
// Public
//

const call = (content, type = 'default', callback = null) => {
	let s = content || '';

	if (cleanupTypes[type] !== undefined) {
		for (const option of cleanupTypes[type].useReplacements) {
			s = s.replaceAll(new RegExp(option.rx, 'gm'), option.replaceBy);
		}

		if (typeof callback === 'function') {
			s = callback(s);
		}
	}

	return new Promise(function(resolve) {
		resolve(s);
	});
};

const removeEmptyTags = (content) => {
	// P-Tags: leere, auch mit einem geschützen Leerzeichen, versehene Tags entfernen.
	let s = content.replaceAll(new RegExp('<p[^>]*>\s*(&nbsp;)*\s*</p>', 'gm'), '');

	// P-Tags: leere oder mit Leerzeichen versehene Tags entfernen.
	s = s.replaceAll(new RegExp('<p[^>]*>\s*<\/p>', 'gm'), '');

	// P-Tags: Self closing Tagsentfernen.
	s = s.replaceAll(new RegExp('<p\s *\/>', 'gm'), '');

	return s;
};

const allowPaste = (type = 'default') => {
	return (cleanupTypes[type] === undefined) ? cleanupTypes['default'].allowPaste : cleanupTypes[type].allowPaste;
};

const Cleanup = {
	call,
	allowPaste,
	removeEmptyTags
};

// Export
export default Cleanup;
