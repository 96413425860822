/**
 * Prins: Config > l10n
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import l10nClient from './../../client/config/l10n';
import {extend} from "../../shared/utils";

const de = {
	// Aria
	ariaLabelSubmenu       : 'Submenu for %s',
	ariaLabelSubmenuTrigger: 'Open/close submenu for %s',
	// Eintrag
	itemGoTo: 'gehe zu Eintrag {{index}}',
	itemNext: 'nächster Eintrag',
	itemPrev: 'vorheriger Eintrag',
	// Bild
	imageNext: 'nächstes Bild',
	imagePrev: 'vorheriges Bild',
	imageZoom: 'Bild vergrößern',
	// Slider
	slideGoTo: 'gehe zu Slide {{index}}',
	slideNext: 'nächster Slide',
	slidePrev: 'vorheriger Slide',
	// Modal
	modalClose: 'Overlay schließen',
	modalOpen : 'Overlay öffnen',
	// Begrifflichkeiten
	close              : 'schließen',
	closeEsc           : 'per ESC-Taste schließen',
	download           : 'herunterladen',
	edit               : 'bearbeiten',
	editContent        : 'Inhalt bearbeiten',
	error              : 'Ein Fehler ist aufgetreten',
	formatDate         : 'd M, Y',
	formatTime         : 'H:i',
	formDataOverwritten: 'Durch die Datenübernahme werden die Formulardaten überschrieben.',
	next               : 'weiter',
	now                : 'jetzt',
	prev               : 'zurück',
	preview            : 'Vorschau',
	remove             : 'entfernen',
	removeFile         : 'Datei entfernen',
	reset              : 'zurücksetzen',
	searchTerm         : 'Suchbegriff',
	selected           : 'ausgewählt',
	selectFile         : 'Datei wählen',
	selection          : 'Auswahl',
	sortRecord         : 'Reihenfolge des Datensatzes ändern'
};

const en = {
	// Aria
	ariaLabelSubmenu       : 'Submenu for %s',
	ariaLabelSubmenuTrigger: 'Open/close submenu for %s',
	// Eintrag
	itemGoTo: 'go to item {{index}}',
	itemNext: 'next item',
	itemPrev: 'previous item',
	// Bild
	imageNext: 'next image',
	imagePrev: 'previous image',
	imageZoom: 'zoom image',
	// Slider
	slideGoTo: 'go to slide {{index}}',
	slideNext: 'next slide',
	slidePrev: 'previous slide',
	// Modal
	modalClose: 'close overlay',
	modalOpen : 'open overlay',
	// Begrifflichkeiten
	close              : 'close',
	closeEsc           : 'close with the ESC key',
	download           : 'download',
	edit               : 'edit',
	editContent        : 'Edit content',
	error              : 'An error has occurred',
	formatDate         : 'm d, Y',
	formatTime         : 'H:i',
	formDataOverwritten: 'The form data is overwritten by the data transfer.',
	next               : 'next',
	now                : 'now',
	prev               : 'vorherig',
	preview            : 'Preview',
	remove             : 'remove',
	removeFile         : 'Remove file',
	reset              : 'reset',
	searchTerm         : 'Search term',
	selected           : 'selected',
	selection          : 'Selection',
	selectFile         : 'Select file',
	sortRecord         : 'Change order of data record'
};

export default extend({}, {
	de,
	en
}, l10nClient);
