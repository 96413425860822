/**
 * Prins: Main
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getRootVar, setRootVar} from '../shared/utils/index';

import config from './config';
import l10n from './config/l10n';
import components from './components/components';
import initial from './initial';
import layout from './layout';

import TranslationManager from '../shared/service/translation-manager';
import AppPreloader from "./components/custom/app-preloader";

// --------
// Let's go
// --------

//
// In `window` das Objekt ´Prins´ zusammenstellen (Konfiguratione, Übersetzungen etc.)
//

window.Prins = window.Prins || {};

// Übersetzungen (l10n)
if (!window.Prins.tm) {
	window.Prins.tm = new TranslationManager();

	window.Prins.tm.addLanguages(l10n);
}

window.Prins.prefix = getRootVar('global-rootvar-prefix');
window.Prins.config = config;

window.Prins.config.breakpointMenuCanvas = parseInt(getRootVar(`${window.Prins.prefix}breakpoint-menu-canvas`), 10);
window.Prins.config.breakpointModuleFilter = parseInt(getRootVar(`${window.Prins.prefix}breakpoint-module-filter`), 10);

//
// Anwendung starten
//

document.addEventListener('DOMContentLoaded', function() {
	components();
	initial();
	layout();

	AppPreloader.hide(() => {
		setTimeout(() => {
			setRootVar(`${window.Prins.prefix}scaffolding-duration`, getRootVar(`${window.Prins.prefix}transition-average`));
		}, 150);
	});
});
