/**
 * Prins: Components / Rte
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {noop} from '../../../../shared/utils/index';
import {
	truncate,
	normalizeWhitespaces,
	removeParagraphs,
	stripTags
} from "../../../../shared/utils/string";
import {isObject, isString} from "../../../../shared/utils/is";
import {load} from "../../../../shared/utils/load";

import Cleanup from './config/cleanup';
import types from './config/types';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

import Spinner from '../../../../shared/components/spinner';

// -------
// Private
// -------

const NAME      = 'rte';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

/**
 * RTE-Modal-Instanz.
 *
 * @type {null}
 */
let rteModal = null;

/**
 * Überwachung von RTE's (´in view´)
 *
 * @type {IntersectionObserver}
 */
const observerInView = new IntersectionObserver((entries) => {
	for (const entry of entries) {
		const element = entry.target;

		if (entry.isIntersecting) {
			EventHandler.trigger(element, `setup${EVENT_KEY}`);
		} else {
			// EventHandler.trigger(element, `disable${EVENT_KEY}`);
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

/**
 *
 * @param {function} callback
 */
const loadDependencies = (callback = noop) => {
	window.Prins.rte = window.Prins.rte || {
		instances: [],
		config   : types, // extend({}, config, configClient),
		render   : '' //render
	};

	load('build/prins/vendors/ckeditor/classic.js').then(() => {
		if (window.ClassicEditor === undefined) {
			if (window.Prins.error) {
				window.Prins.error.show({
					msg: `The rte dependency ´CkEditor´ cannot be loaded/initialized`
				});
			} else {
				throw new Error(`The rte dependency ´CkEditor´ cannot be loaded/initialized`);
			}
		} else {
			callback();
		}
	});
};

/**
 * Anzahl an ´<br/>´ in einem String zurückgeben.
 *
 * @param s
 * @returns {number}
 */
const countBreaks = (s = '') => {
	const found = s.match(/<br/g);

	return (found) ? found.length : 0;
};

/**
 * Container für Text-, Wortstatistik anbinden
 *
 * @param {HTMLElement} element
 * @returns {{container: HTMLElement, characters: HTMLElement, words: HTMLElement}}
 */
const addStatistic = (element) => {
	const container  = Manipulator.elementAppend(`<div class="rte-stats"></div>`, element);
	const characters = Manipulator.elementAppend(`<span class="characters"></span>`, container);
	const words      = Manipulator.elementAppend(`<span class="words"></span>`, container);

	return {
		characters,
		container,
		words
	};
};

const handlePasteContent = (context, editor, data, event) => {
	if (editor.isReadOnly) {
		return;
	}

	const dataTransfer = data.dataTransfer;

	let content = '';

	if (dataTransfer.getData('text/html')) {
		content = dataTransfer.getData('text/html');
	} else if (dataTransfer.getData('text/plain')) {
		content = `<p>${dataTransfer.getData('text/plain')}</p>`;
	}

	Cleanup
		.call(content, context)
		.then(content => {
			content = editor.data.htmlProcessor.toView(content);

			const modelFragment = editor.data.toModel(content);

			if (modelFragment.childCount === 0) {
				return;
			}

			editor.model.insertContent(modelFragment);
		});

	event.stop();
};

/**
 * Modal ´RTE´ initialisiere und bereitstellen.
 */
const rteModalSetup = () => {
	if (rteModal) {
		return;
	}

	const container   = SelectorEngine.findOne('#modal-rte');

	if (!container) {
		return;
	}

	const modalTitle  = SelectorEngine.findOne('[data-modal-title]', container);
	const modalBody   = SelectorEngine.findOne('[data-modal-body]', container);
	const modalFooter = SelectorEngine.findOne('.modal-footer', container);
	const controlSave = SelectorEngine.findOne('button[data-modal-control="save"]', container);

	// Statistikcontainer vorbereiten (Anzahl Zeichen, Wörter)
	let stats;

	let rteInstance = null;

	Manipulator.setDisabled(controlSave);

	// Modal wird geöffnet, Daten eintragen.
	container.addEventListener('show.bs.modal', (event) => {
		const trigger = event.relatedTarget;

		Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);

		//
		// Benötigte Refrenzen bestimmen.
		//

		// ... aktuelles DOM-Element aus dem trigger holen.
		const curRteElement   = Data.get(trigger, `${DATA_KEY}.element`);
		// ... Formelement aus dem aktuellen DOM-Element holen.
		const curRteFormField = Data.get(curRteElement, `${DATA_KEY}.formField`);
		// Elternformular.
		const curRteParentForm = SelectorEngine.parents(curRteFormField, 'form');
		// ... RTE-Label aus dem aktuellen DOM-Element holen.
		const curRteLabel     = Data.get(curRteElement, `${DATA_KEY}.label`);
		// ... Kontext aus dem aktuellen DOM-Element holen.
		const curRteContext    = Data.get(curRteElement, `${DATA_KEY}.context`);
		// ... Editorparameter aus dem aktuellen DOM-Element holen.
		const curRteParams    = Data.get(curRteElement, `${DATA_KEY}.params`);
		// ... nur ´soft line break´ verwenden?
		const curRteSoftLineBreak = Data.get(curRteElement, `${DATA_KEY}.softLineBreak`);

		modalTitle.textContent = curRteLabel;

		//
		// RTE-Container und -Instanz
		//

		let currentContent = normalizeWhitespaces(curRteFormField.value);

		currentContent = removeParagraphs(currentContent);

		const rteContainer = Manipulator.elementAppend('<div class="rte"></div>', modalBody);
		const rteInst = Manipulator.elementAppend(`<div class="rte-instance">${currentContent}</div>`, rteContainer);

		stats = addStatistic(modalFooter);

		// Statistikcontainer vorbereiten (Anzahl Zeichen, Wörter)

		if (curRteParams.wordCount) {
			curRteParams.wordCount.onUpdate = (count) => {
				stats.words.textContent      = count.words;
				stats.characters.textContent = count.characters;
			};
		}

		window.ClassicEditor
			.create(rteInst, curRteParams)
			.then((editor) => {
				const editingView     = editor.editing.view;
				const editorModel     = editor.model;

				if (curRteSoftLineBreak) {
					// let content = normalizeWhitespaces(curRteFormField.value);
					// content = removeParagraphs(content);
					//
					// editor.setData(content);

					editingView.document.on('enter', (evt, data) => {
						// Setze immer ein ´soft line break´;
						editor.execute('shiftEnter');

						// Beispiel um das Handling von ´soft-´ & ´hard line breaks´ zu vertauschen ;).
						// if (data.isSoft) {
						// 	// Es wurde ein ´soft line break´ eingegeben, setze aber ein ´hard line break´.
						// 	editor.execute('shiftEnter');
						// } else {
						// 	// Es wurde ein ´hard line break´ eingegeben, setze aber ein ´soft line break´.
						// 	editor.execute('shiftEnter');
						// }

						data.preventDefault();
						evt.stop();
					}, {priority: 'high'});
				} else {
					// editor.setData(curRteFormField.value);
				}

				// Event ´clipboard´: Wird ausgelöst, wenn der Nutzer Inhalt einfügt.
				editingView.document.on('clipboardInput', (event, data) => {
					handlePasteContent(curRteContext, editor, data, event);
				});

				// Änderungen überwachen.
				editorModel.document.on('change', () => {
					// Nur wenn sich der Inhalt ändert, muss das ´Source element´ (Textarea) aktualisiert werden.
					if (editorModel.document.differ.getChanges().length > 0) {
						editor.updateSourceElement();

						// Formularänderung dem Elternformular bekannt geben!
						if (curRteParentForm[0]) {
							EventHandler.trigger(curRteParentForm[0], 'changeDetected');
						}

						// Den Save-Button (im Modal) aktivieren.
						Manipulator.setDisabled(controlSave, false);
					}
				});

				// editorModel.document.on('change:data', (event) => {
				// 	console.log('change:data', event);
				// });

				// Aktuelle RTE-Instanz speichern.
				rteInstance = editor;
			})
			.catch(function (error) {
				if (window.Prins.error) {
					window.Prins.error.show({
						msg: `(Rte) ${error}`
					});
				} else {
					throw new Error(error);
				}
			});

		// Save-Button: Inhalt aktueller RTE-Instanz im Zielelement (Formular) speichern.
		EventHandler.on(controlSave, `click${EVENT_KEY}`, (event) => {
			event.preventDefault();

			let content = rteInstance.getData();

			if (curRteSoftLineBreak) {
				content = normalizeWhitespaces(content);
				content = removeParagraphs(content);

				curRteFormField.value = content;
			} else {
				curRteFormField.value = content;
			}

			EventHandler.trigger(curRteElement, `previewUpdate${EVENT_KEY}`);

			rteModal.hide();
		});
	});

	// Modal wird geschlossen.
	// container.addEventListener('hide.bs.modal', (event) => {
	// 	const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);
	//
	// 	console.log('RTE modal hide', event);
	// });

	// Modal wurde geschlossen, Daten entfernen.
	container.addEventListener('hidden.bs.modal', (event) => {
		const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);

		// Save-Bbutton (im Modal) deaktivieren & Events entfernen
		EventHandler.off(controlSave, `click${EVENT_KEY}`);
		Manipulator.setDisabled(controlSave);

		// RTE-Instanz ´zerstören´.
		rteInstance
			.destroy()
			.catch(error => {
				if (window.Prins.error) {
					window.Prins.error.show({
						msg: `(Rte) ${error}`
					});
				} else {
					throw new Error(error);
				}
			});

		// Modal-Container ´leeren´.
		modalTitle.textContent = "";
		modalBody.textContent = "";

		stats.container.remove();

		// Set focus back to trigger.
		if (trigger) {
			setTimeout(() => trigger.focus());
		}
	});

	// Modalinstanz speichern.
	rteModal = new bootstrap.Modal(container, {
		backdrop: 'static',
		focus: false
	});
};

/**
 * Standard-RTE initialisieren.
 *
 * @param {HTMLElement} element
 */
const setupDefaultRte = (element) => {
	const formField     = Data.get(element, `${DATA_KEY}.formField`);
	const params        = Data.get(element, `${DATA_KEY}.params`);
	const softLineBreak = Data.get(element, `${DATA_KEY}.softLineBreak`);
	const context       = Data.get(element, `${DATA_KEY}.context`);

	// Elternformular.
	const form = SelectorEngine.parents(formField, 'form');

	// Statistikcontainer vorbereiten (Anzahl Zeichen, Wörter)
	const stats = addStatistic(element);

	if (params.wordCount) {
		params.wordCount.onUpdate = (count) => {
			stats.words.textContent      = count.words;
			stats.characters.textContent = count.characters;
		};
	}

	window.ClassicEditor
		.create(formField, params)
		.then((editor) => {
			const editingView     = editor.editing.view;
			const editorModel     = editor.model;
			// editor.ui.view.editable.element.style.minHeight = '200px';

			if (softLineBreak) {
				// Event ´enter´: Wird ausgelöst, wenn der Nutzer die Entertaste betätigt.
				editingView.document.on('enter', (evt, data) => {
					// Setze immer ein ´soft line break´;
					editor.execute('shiftEnter');

					// Beispiel um das Handling von ´soft-´ & ´hard line breaks´ zu vertauschen ;).
					// if (data.isSoft) {
					// 	// Es wurde ein ´soft line break´ eingegeben, setze aber ein ´hard line break´.
					// 	editor.execute('shiftEnter');
					// } else {
					// 	// Es wurde ein ´hard line break´ eingegeben, setze aber ein ´soft line break´.
					// 	editor.execute('shiftEnter');
					// }

					data.preventDefault();
					evt.stop();
				}, {priority: 'high'});
			}

			// Event ´clipboard´: Wird ausgelöst, wenn der Nutzer Inhalt einfügt.
			editingView.document.on('clipboardInput', (event, data) => {
				handlePasteContent(context, editor, data, event);
			});

			// Änderungen überwachen.
			editorModel.document.on('change', () => {
				// Nur wenn sich der Inhalt ändert, muss das ´Source element´ (Textarea) aktualisiert werden.
				if (editorModel.document.differ.getChanges().length > 0) {
					editor.updateSourceElement();

					// Formularänderung dem Elternformular bekannt geben!
					if (form[0]) {
						EventHandler.trigger(form[0], 'changeDetected');
					}
				}
			});

			// editor.model.document.on('change:data', (event) => {
			// 	console.log('change:data', event);
			// });

			//
			// Instanz speichern.
			//

			window.Prins.rte.instances.push(editor);

			Data.set(element, `${DATA_KEY}.editor`, editor);
		})
		.catch(function (error) {
			if (window.Prins.error) {
				window.Prins.error.show({
					msg: `(Rte) ${error}`
				});
			} else {
				throw new Error(error);
			}
		});

	EventHandler.trigger(element, `initialized${EVENT_KEY}`);
};

/**
 * Modal-RTE initialisieren.
 *
 * @param {HTMLElement} element
 */
const setupModalRte = (element) => {
	const formField = Data.get(element, `${DATA_KEY}.formField`);

	// Preview container bereitstellen.
	const preview = Manipulator.elementAppend(`<div class="rte__preview"></div>`, element);

	// Button (RTE-Modal öffnen) integrieren.
	const button = Manipulator.elementAppend(`<button class="button -secondary -s" type="button" title="${window.Prins.tm.translate('editContent')}">
		<span class="button__prefix">${window.Prins.config.icons.edit}</span>
		<span class="button__label">${window.Prins.tm.translate('edit')}</span>
	</button>`, element);

	// ... zugehörige Elementreferenz im Button speichern (wird im Modal benötigt).
	Data.set(button, `${DATA_KEY}.element`, element);

	//
	// Events
	//

	// ... ´preview update´ (wird bei RTE-Änderung getriggert).
	EventHandler.on(element, `previewUpdate${EVENT_KEY}`, () => {
		preview.textContent = truncate(formField.value, 600);
	});

	if (rteModal) {
		// ... ´click´ (öffnen des Modals).
		EventHandler.on(button, `click${EVENT_KEY}`, (event) => {
			event.preventDefault();

			rteModal.show(event.delegateTarget);
		});
	} else {
		Manipulator.setDisabled(button);
	}

	EventHandler.trigger(element, `initialized${EVENT_KEY}`);
	EventHandler.trigger(element, `previewUpdate${EVENT_KEY}`);
};

/**
 * Title-RTE initialisieren.
 *
 * @param {HTMLElement} element
 */
const setupTitleRte = (element) => {
	const form      = Data.get(element, `${DATA_KEY}.form`);
	const formField = Data.get(element, `${DATA_KEY}.formField`);
	const params    = Data.get(element, `${DATA_KEY}.params`);
	const context   = Data.get(element, `${DATA_KEY}.context`);
	const breaksMax = 1;

	let breaksCurrent = 0;

	// const foundBreaks = (s = '') => {
	// 	const found = s.match(/<br/g);
	//
	// 	return (found) ? found.length : 0;
	// };

	// Zeichen- & Wortzähler?
	if (params.wordCount) {
		const stats = addStatistic(element);

		params.wordCount.onUpdate = (count) => {
			stats.words.textContent      = count.words;
			stats.characters.textContent = count.characters;
		};
	}

	let editableArea;

	window.ClassicEditor
		.create(formField, params)
		.then((editor) => {
			const editingView     = editor.editing.view;
			const editorModel     = editor.model;
			// const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
			// const pastePlainTextPlugin = editor.plugins.get('PastePlainText');

			// Editierbares Element des Editors finden. Wird benötigt, um die Toolbar ein- oder auszublenden.
			editableArea = SelectorEngine.findOne('.ck-editor__editable', element);

			// Zeilenumbrüche gegenprüfen.
			let content = editor.getData();

			breaksCurrent = countBreaks(content);

			if (breaksCurrent > breaksMax) {
				// Mehr Zeilenumbrüche als erlaubt, dann entferne alle.
				content = stripTags(content);

				breaksCurrent = 0;
			}

			//
			// Events
			//

			// Event ´enter´: Wird ausgelöst, wenn der Nutzer die Entertaste betätigt.
			editingView.document.on('enter', (evt, data) => {
				// Setze immer ein ´soft line break´;
				if (breaksCurrent < breaksMax) {
					editor.execute('shiftEnter');
				}

				// Beispiel um das Handling von ´soft-´ & ´hard line breaks´ zu vertauschen ;).
				// if (data.isSoft) {
				// 	// Es wurde ein ´soft line break´ eingegeben, setze aber ein ´hard line break´.
				// 	editor.execute('shiftEnter');
				// } else {
				// 	// Es wurde ein ´hard line break´ eingegeben, setze aber ein ´soft line break´.
				// 	editor.execute('shiftEnter');
				// }

				data.preventDefault();
				evt.stop();
			}, {priority: 'high'});

			// Event ´clipboard´: Wird ausgelöst, wenn der Nutzer Inhalt einfügt.
			editingView.document.on('clipboardInput', (event, data) => {
				handlePasteContent(context, editor, data, event);
			});
			/*editingView.document.on('clipboardInput', (event, data) => {
				if (editor.isReadOnly) {
					return;
				}

				const dataTransfer = data.dataTransfer;

				let content = '';

				if (dataTransfer.getData('text/html')) {
					content = dataTransfer.getData('text/html');
				} else if (dataTransfer.getData('text/plain')) {
					content = `<p>${dataTransfer.getData('text/plain')}</p>`;
				}

				Cleanup
					.call(content, context)
					.then(content => {
						content = editor.data.htmlProcessor.toView(content);

						const modelFragment = editor.data.toModel(content);

						if (modelFragment.childCount === 0) {
							return;
						}

						editorModel.insertContent(modelFragment);
					});

				event.stop();
			});*/

			// Event ´focus´: Wird ausgelöst, wenn in den Editor geklickt wird (Toobar einblenden).
			editingView.document.on('focus', () => {
				Manipulator.addClass(element, '-focused');
			});

			// Event ´blur´: Wird ausgelöst, wenn der Editor den Focus verliert (Achtung ... zweierlei Maß!).
			editingView.document.on('blur', () => {
				// Ob der Nutzer den Editor "komplett" verlassen hat, wird derzeit nur an dem Vorhandensein einer
				// bestimmten CSS-Klasse erkannt.
				setTimeout(() => {
					// Toolbar ausblenden?
					if (editableArea && editableArea.classList.contains('ck-blurred')) {
						Manipulator.removeClass(element, '-focused');
					}
				}, 250);
			});

			// Event ´change´: Wird ausgelöst, wenn Inhaltsänderungen erkannt werden.
			editorModel.document.on('change', () => {
				// Nur wenn sich der Inhalt ändert, muss das ´Source element´ (Textarea) aktualisiert werden.
				if (editorModel.document.differ.getChanges().length > 0) {
					editor.updateSourceElement();

					breaksCurrent = countBreaks(editor.getData());

					// Formularänderung dem Elternformular bekannt geben!
					if (form[0]) {
						EventHandler.trigger(form, 'changeDetected');
					}
				}
			});

			// editorModel.document.on('change:data', (event) => {
			// 	console.log('change:data', event);
			// });

			//
			// Instanz speichern.
			//

			window.Prins.rte.instances.push(editor);

			Data.set(element, `${DATA_KEY}.editor`, editor);
		})
		.catch(function (error) {
			if (window.Prins.error) {
				window.Prins.error.show({
					msg: `(Rte) ${error}`
				});
			} else {
				throw new Error(error);
			}
		});

	EventHandler.trigger(element, `initialized${EVENT_KEY}`);
};

// ------
// Public
// ------

/**
 * RTE-Container parsen und vorbereiten.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	const formField      = SelectorEngine.findOne('textarea', element);
	const formFieldLabel = (formField.getAttribute('id')) ? SelectorEngine.findOne(`label[for="${formField.getAttribute('id')}"]`) : null;
	const form           = SelectorEngine.parents(formField, 'form');

	const isTypeTitle = element.dataset.type === 'title';
	const asModal     = (isTypeTitle) ? false : Manipulator.getDataAttribute(element, 'as-modal');

	// Ladeinfo integrieren.
	Spinner.addTo(element);

	// RTE-Modal immer bereitstellen.
	rteModalSetup();

	// Daten im Elemrnt speichern (kontextübergrifender Zugriff wird erleichtert).
	Data.set(element, `${DATA_KEY}.context`, element.dataset.type);
	Data.set(element, `${DATA_KEY}.form`, form[0]);
	Data.set(element, `${DATA_KEY}.formField`, formField);
	Data.set(element, `${DATA_KEY}.softLineBreak`, Manipulator.getDataAttribute(element, 'soft-line-break'));
	Data.set(element, `${DATA_KEY}.label`, (formFieldLabel) ? formFieldLabel.textContent : '');
	Data.set(element, `${DATA_KEY}.params`, o);

	// Globale Formularvalidierung des Formularfeldes behandeln.
	EventHandler.on(formField, 'validationFailed.ifab', () => {
		Manipulator.addClass(element, '-validation-failed');
	});

	EventHandler.on(formField, 'validationSuccess.ifab', () => {
		Manipulator.removeClass(element, '-validation-failed');
	});

	// Event ´initialized´ (wird nach Bereitstellung eines RTE getriggert).
	EventHandler.on(element, `initialized${EVENT_KEY}`, () => {
		Manipulator.addClass(element, '-initialized');

		Data.set(element, `${DATA_KEY}.initialized`, true);

		Spinner.removeFrom(element);
	});

	// Event ´setup´ wird über den Observer getriggert.
	EventHandler.one(element, `setup${EVENT_KEY}`, () => {
		if (isTypeTitle) {
			setupTitleRte(element);
		} else {
			if (asModal) {
				setupModalRte(element);
			} else {
				setupDefaultRte(element);
			}
		}
	});

	// Elementüberwachung durch Observer starten.
	observerInView.observe(element);

	return element;
};

/**
 * Methode um gezielt ein RTE zu initialisieren.
 *
 * @param {HTMLElement} element
 * @param {*} m
 * @returns {HTMLElement}
 */
const preRender = (element, m) => {
	let _params = types.default;

	if (isString(m) && types.hasOwnProperty(m)) {
		_params = types[m];
	} else if (isObject(m)) {
		_params = m;
	}

	loadDependencies(() => {
		render(element, _params);
	});

	return element;
};

/**
 * Alle vorhandenen ´RTE´ initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	const collection = SelectorEngine.find('[data-rte]');
	const group      = [];

	if (collection.length > 0) {
		loadDependencies(() => {
			for (const element of collection) {
				// Wurde Element schon initialisiert?
				if (!Data.get(element, `${DATA_KEY}.initialized`)) {
					// Check RTE-Kontext (´default´ wenn fehlerhaft).
					element.dataset.type = (element.dataset.type !== '' && types.hasOwnProperty(element.dataset.type)) ? element.dataset.type : 'default';

					group.push(render(element, types[element.dataset.type]));
				}
			}
		});
	}

	return group;
};

// Export
export default {
	init  : init,
	render: preRender
};

// const x = {
// 	toolbar : {
// 		items                 : [
// 			"undo", "redo", "|",
// 			"findAndReplace", "removeFormat", "|",
// 			"heading", "style", "|",
// 			"link", "insertTable", "specialCharacters", "horizontalLine", "|",
// 			"codeBlock", "showBlocks", "sourceEditing", "|",
// 			"-",
// 			"bold", "italic", "strikethrough", "subscript", "superscript", "|",
// 			"bulletedList", "numberedList", "|",
// 			"blockQuote", "alignment", "outdent", "indent", "pageBreak"
// 		],
// 		shouldNotGroupWhenFull: true
// 	},
// 	language: "de",
// 	image   : {toolbar: ["imageTextAlternative", "toggleImageCaption", "imageStyle:inline", "imageStyle:block", "imageStyle:side"]},
// 	table   : {contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties"]}
// 	// -------
// 	toolbar : {
// 		items: [
// 		"heading", "|",
// 		"bold", "italic", "link", "bulletedList", "numberedList", "|",
// 		"outdent", "indent", "|",
// 		"blockQuote", "insertTable", "undo", "redo",
// 		"alignment", "horizontalLine", "pageBreak",
// 		"removeFormat", "showBlocks", "specialCharacters",
// 		"sourceEditing", "subscript", "superscript",
// 		"strikethrough", "highlight", "code"
// 		]
// 	},
// 	language: "de",
// 	table   : {contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties"]}
// };
