/**
 * Prins: Components / Rte / Config / Types
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../../../../shared/utils/index';

import core from './core';
import plugins from './plugins';
import toolbar from './toolbar';

import client from '../../../../../client/components/custom/rte/config';

// -------
// Private
// -------

//
// RTE-Standardtypen
//

// RTE ´Simple´
const toolbarSimple = extend({},
	toolbar.simple,
	(client.toolbar.simple) ?? {}
);
const typeSimple = extend({},
	core,
	client.core || {},
	plugins.simple,
	client.plugins.simple || {},
	{
		toolbar: toolbarSimple
	}
);

// RTE ´Title´
const toolbarTitle = extend({},
	toolbar.title,
	(client.toolbar.title) ?? {}
);
const typeTitle = extend({},
	core,
	client.core || {},
	plugins.title,
	client.plugins.title || {},
	{
		toolbar: toolbarTitle
	}
);

// RTE ´Default´
const toolbarDefault = extend({},
	toolbar.default,
	(client.toolbar.default) ?? {}
);
const typeDefault = extend({},
	core,
	client.core || {},
	plugins.default,
	client.plugins.default || {},
	{
		toolbar: toolbarDefault
	}
);

// RTE ´Full´
const toolbarFull = extend({},
	toolbar.full,
	(client.toolbar.full) ?? {}
);
const typeFull = extend({},
	core,
	client.core || {},
	plugins.full,
	client.plugins.full || {},
	{
		toolbar: toolbarFull
	}
);

// RTE's
const types = extend({}, {
	default: typeDefault,
	full   : typeFull,
	simple : typeSimple,
	title  : typeTitle
}, client.types || {});

// ------
// Public
// ------

// Export
export default types;
