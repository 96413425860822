/**
 * Prins: Components / Custom > Snippets
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

// -------
// Private
// -------

// ------
// Public
// ------

const snippets = () => {
	//
	// Tooltip initialisieren
	//

	const collectionTooltips = SelectorEngine.find('[data-bs-toggle="tooltip"]');
	const activeTooltips     = [...collectionTooltips].map(el => new bootstrap.Tooltip(el, {
		position: 'auto'
	}));

	//
	// Line clamp switchable
	//

	const collectionLineClamp = SelectorEngine.find('[data-line-clamp-switchable]');

	if (collectionLineClamp.length) {
		for (const element of collectionLineClamp) {
			EventHandler.one(element, 'click', (event) => {
				event.target.classList.remove('line-clamp');
				delete event.target.dataset.lineClampSwitchable;
			});
		}
	}
};

// Export
export default snippets;
