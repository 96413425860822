/**
 * Prins: Components / Rte / Config / Plugins
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// ------
// Public
// ------

const heading = {
	options: [
		{
			class: 'ck-paragraph_prins-paragraph',
			model: 'paragraph',
			title: 'Paragraph'
		}, {
			class: 'ck-heading_prins-h2',
			model: 'heading2',
			title: 'Heading 2',
			view : {
				// classes: 'prins-h2',
				name: 'h2'
			}
		}, {
			class: 'ck-heading_prins-h3',
			model: 'heading3',
			title: 'Heading 3',
			view : {
				// classes: 'prins-h3',
				name: 'h3'
			}
		}, {
			class: 'ck-heading_prins-h4',
			model: 'heading4',
			title: 'Heading 4',
			view : {
				// classes: 'prins-h4',
				name: 'h4'
			}
		}, {
			class: 'ck-heading_prins-h5',
			model: 'heading5',
			title: 'Heading 5',
			view : {
				// classes: 'prins-h5',
				name: 'h5'
			}
		}, {
			class: 'ck-heading_prins-h6',
			model: 'heading6',
			title: 'Heading 6',
			view : {
				// classes: 'prins-h6',
				name: 'h6'
			}
		}
	]
};

const link = {
	addTargetToExternalLinks: true,
	defaultProtocol         : 'https://'
};

const list = {
	properties: {
		styles    : true,
		startIndex: true,
		reversed  : true
	}
};

const table = {
	contentToolbar : ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'],
	defaultHeadings: {rows: 1, columns: 0}
};

const wordCount = {
	displayWords     : false,
	displayCharacters: true
};

// Export
export default {
	default: {
		heading,
		wordCount
	},
	full : {
		heading,
		link,
		list,
		table,
		wordCount
	},
	simple : {
		wordCount
	},
	title : {
		wordCount
	}
};
