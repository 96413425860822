/**
 * Prins: Components / Rte / Config / Toolbar
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// ------
// Public
// ------

// Export
export default {
	default: {
		items: [
			'undo', 'redo', '|',
			'bold', 'italic', 'strikethrough', 'superscript', 'subscript', 'alignment', '|',
			'link', '|',
			'specialCharacters', '|',
			'showBlocks', 'removeFormat',// 'sourceEditing'
		],
		shouldNotGroupWhenFull: false
	},
	full : {
		items: [
			'undo', 'redo', '|',
			'heading', '|',
			'bold', 'italic', 'strikethrough', 'superscript', 'subscript', 'alignment', '|',
			'blockQuote', 'bulletedList', 'numberedList', '|',
			'link', '|',
			'insertTable', 'specialCharacters', '|',
			'showBlocks', 'removeFormat',// 'sourceEditing',
		],
		shouldNotGroupWhenFull: false
	},
	simple : {
		items: [
			'undo', 'redo', '|',
			'bold', 'italic', 'strikethrough', 'superscript', 'subscript', '|',
			'showBlocks', 'removeFormat',// 'sourceEditing',
		],
		shouldNotGroupWhenFull: false
	},
	title : {
		items: [
			'undo', 'redo', '|',
			'superscript', 'subscript', '|',
			'removeFormat',// 'sourceEditing',
		],
		shouldNotGroupWhenFull: false
	}
};
