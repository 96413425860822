/**
 * Prins: Components / Filter list
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {load} from "../../../../shared/utils/load";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'filter-list';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

// const DEFAULTS = {
// 	container: null
// };

const getSortData = (container) => {
	const items  = SelectorEngine.find('[data-sort]', container);
	const labels = [];
	const types  = [];

	for (const item of items) {
		labels.push(item.textContent);

		types.push({
			data: [item.dataset.sort]
		});
	}

	// <div className="form-group">
	// 	<label className="form-label" htmlFor="f-user-edit-187849919-first-name">Vorname<span className="form-label__required">*</span></label><input className="form-control" id="f-user-edit-187849919-first-name" name="vorname" placeholder="" required="required" type="text" value="Erik">
	// </div>

	return {
		labels,
		types
	};
};

const setupControls = (instance) => {
	const context  = Data.get(instance.listContainer, `${DATA_KEY}.context`);
	const sortData = Data.get(instance.listContainer, `${DATA_KEY}.sort-data`);
	const control  = SelectorEngine.findOne(`[data-${NAME}-control="${context}"]`);

	if (!control) {
		return;
	}

	const controlForm = (control.tagName.toLowerCase() === 'form') ? control : SelectorEngine.findOne('form', control);

	// Ist `control` ein Formular, oder gibt es ein Formular in `control`, dann unterbinde das Submit-Event.
	if (controlForm) {
		EventHandler.on(controlForm, `submit${EVENT_KEY}`, (event) => {
			event.preventDefault();
			event.stopPropagation();
		});
	}

	//
	// Suchfeld
	//

	const search = SelectorEngine.findOne('.search', control);

	if(search) {
		EventHandler.on(search, 'clearable.clear', (event) => {
			EventHandler.trigger(event.target, 'keyup');
		});

		EventHandler.on(search, 'keyup', (event) => {
			instance.search(event.target.value);
		});

		if (sortData.labels.length) {
			const ariaLabel = Manipulator.getAria(search, 'label');
			const s         = `${ariaLabel} ${sortData.labels.join(', ')}`;

			Manipulator.setAria(search, 'label', s.trim());

			search.setAttribute('placeholder', s.trim());
		}
	}

	//
	// Auswahllisten
	//

	const selects = SelectorEngine.find('select', control);

	for (const select of selects) {
		if (select.dataset.filter) {
			EventHandler.on(select, `change${EVENT_KEY}`, (event) => {
				const val = event.target.value;

				if (val === 'all') {
					// instance.search();

					instance.filter();
				} else {
					// instance.search(val, [select.dataset.filter]);

					instance.filter(function (item) {
						return (item.values()[select.dataset.filter] === val);
					});
				}
			});
		} else {
			// Gibt es kein Data-Attribut ´data-filter-column´, dann kann mit dem Select nichts agefangen werden.
			Manipulator.setDisabled(select);
		}
	}
};

/**
 * ´Sort-, Filterlist´-Container initialisieren.
 *
 * @param {HTMLElement} container
 * @returns {HTMLElement}
 */
const render = (container) => {
	// Wurde Container schon initialisiert?
	if (Data.get(container, `${DATA_KEY}.initialized`)) {
		return container;
	}

	// Kontext der Filterliste ist zwingend erforderlich.
	const context = container.dataset.filterList;

	if (!context) {
		return container;
	}

	// Verfügbare Sortierungs-, Filterdaten.
	const sortData = getSortData(container);

	Data.set(container, `${DATA_KEY}.sort-data`, sortData);
	Data.set(container, `${DATA_KEY}.context`, context);

	// Sortier-, Filterliste initialisieren.
	const instance = new List(container, {
		valueNames: sortData.types
	});

	instance.on('updated', (event) => {
		if (event.matchingItems.length > 0) {
			Manipulator.removeClass(container, '-empty');
		} else {
			Manipulator.addClass(container, '-empty');
		}
	});

	// Kontrollelementen wie Suche usw. anbinden.
	setupControls(instance);

	// Initialisierungsstatus setzen.
	Data.set(container, `${DATA_KEY}.initialized`, true);

	return container;
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-${NAME}]`);

	if (collection.length) {
		load('build/prins/components/custom/filter-list/bundle.js').then(() => {
			if (window.List === undefined) {
				if (window.Prins.error) {
					window.Prins.error.show({
						msg: "The dependency ´List´ cannot be loaded/initialized"
					});
				} else {
					throw new Error('The dependency ´List´ cannot be loaded/initialized');
				}
			} else {
				for (const element of collection) {
					group.push(render(element));
				}
			}
		});
	}

	return group;
};

// Export
export default {
	init: init
};
