/**
 * Prins (Client): Config > l10n
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// Deutsch
const de = {
	basicStyles : 'Basisstile'
};

// Engisch
const en = {
	basicStyles : 'Basic styles'
};

export default {
	de,
	en
};
