/**
 * Prins: Components / Multi select
 * (Dependency: https://github.com/fabianlindfors/multi.js/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {normalizeAttributeValue} from '../../../../shared/utils/index';
import {load} from "../../../../shared/utils/load";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

import Spinner from '../../../../shared/components/spinner';

// -------
// Private
// -------

const NAME      = 'multi-select';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {
	infoSelected     : '({{selected}} / {{from}})',
	infoSelectedLimit: '({{selected}} / max. {{from}})'
};

const observerInView = new IntersectionObserver((entries) => {
	for (const element of entries) {
		if (element.isIntersecting) {
			render(element.target);
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

const handleSelection = (container, flag) => {
	const select = Data.get(container, `${DATA_KEY}.select`);
	const options = select.getElementsByTagName('option');

	for (const option of options) {
		option.selected = flag;
	}

	EventHandler.trigger(select, 'change');
};

/**
 * ´Multi select´-Container initialisieren.
 *
 * @param {HTMLElement} container
 * @returns {HTMLElement}
 */
const render = (container) => {
	const select           = Data.get(container, `${DATA_KEY}.select`);
	const info             = Data.get(container, `${DATA_KEY}.info`);
	const selectionButtons = SelectorEngine.find('button[data-select-all], button[data-remove-all]', container);

	//
	// Optionen zusammenstellen
	//

	const searchable        = container.dataset.searchable !== undefined;
	const searchPlaceholder = container.dataset.placeholder === undefined ? '' : container.dataset.placeholder;

	const params = {
		'enable_search'      : searchable,
		'search_placeholder' : searchPlaceholder//,
		// 'non_selected_header': (window.Prins.tm) ? window.Prins.tm.translate('selection') : '',
		// 'selected_header'    : (window.Prins.tm) ? window.Prins.tm.translate('selected') : ''
	};

	if (container.dataset.limit !== undefined) {
		params.limit = normalizeAttributeValue(container.dataset.limit);

		for (const selBtn of selectionButtons) {
			Manipulator.setDisabled(selBtn, true);
		}
	}

	//
	// Information bzgl. ´ausgewählt´ integrieren.
	//

	if (info) {
		const infotext  = (params.limit) ? DEFAULTS.infoSelectedLimit : DEFAULTS.infoSelected;
		const countFrom = (params.limit) ?? select.options.length;

		EventHandler.on(select, `updateCount${EVENT_KEY}`, () => {
			const countCurrent = select.selectedOptions.length.toString();

			let s = infotext;

			s = s.replace(new RegExp('{{selected}}', 'gm'), countCurrent);
			s = s.replace(new RegExp('{{from}}', 'gm'), countFrom);

			info.textContent = s;
		});

		EventHandler.on(select, 'change', () => {
			const form = Data.get(select, `${DATA_KEY}.form`);

			EventHandler.trigger(select, `updateCount${EVENT_KEY}`);

			if (form) {
				EventHandler.trigger(form, 'changeDetected', {
					changeDetectedOn : select
				});
			}
		});

		EventHandler.trigger(select, `updateCount${EVENT_KEY}`);
	}

	// Plugin initialisieren.
	multi(select, params);

	EventHandler.on(container, `click${EVENT_KEY}`, 'button[data-select-all], button[data-remove-all]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		handleSelection(container, event.delegateTarget.dataset.selectAll !== undefined);
	});

	// Spinner entfernen.
	Spinner.removeFrom(container);

	Manipulator.addClass(container, '-initialized');

	Data.set(container, `${DATA_KEY}.initialized`, 'true');
};

const preRender = (container) => {
	const form = SelectorEngine.parents(container, 'form')[0] ?? null;
	const select = SelectorEngine.findOne('select', container) ?? null;
	const selectIsMultiple = (select) ? select.multiple : false;
	const info = SelectorEngine.findOne('.multi-select-info', container) ?? null;

	if (Data.get(container, `${DATA_KEY}.initialized`)) {
		return container;
	}

	// Kein Formular, oder kein Select oder kein ...
	if (
		!form ||
		!select ||
		!selectIsMultiple
	) {
		if (select) {
			Manipulator.elementBefore(select, container);

			container.remove();
		}

		return container;
	}

	EventHandler.on(select, 'validationFailed.ifab', () => {
		Manipulator.addClass(container, '-validation-failed');
	});

	EventHandler.on(select, 'validationSuccess.ifab', () => {
		Manipulator.removeClass(container, '-validation-failed');
	});

	// Gegenseitige Elementreferenzen speichern.
	Data.set(container, `${DATA_KEY}.select`, select);
	Data.set(container, `${DATA_KEY}.form`, form);
	Data.set(container, `${DATA_KEY}.info`, info);
	Data.set(select, `${DATA_KEY}.container`, container);
	Data.set(select, `${DATA_KEY}.form`, form);
	Data.set(select, `${DATA_KEY}.info`, info);

	// Spinner dem Container hinzufügen
	Spinner.addTo(container);

	// Container überwachen.
	observerInView.observe(container);
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-${NAME}]`);

	if (collection.length) {
		load('build/prins/components/custom/multi-select/bundle.js').then(() => {
			if (window.multi === undefined) {
				if (window.Prins.error) {
					window.Prins.error.show({
						msg: "The dependency ´multi.js´ cannot be loaded/initialized"
					});
				} else {
					throw new Error('The dependency ´multi.js´ cannot be loaded/initialized');
				}
			} else {
				for (const element of collection) {
					group.push(preRender(element));
				}
			}
		});
	}

	return group;
};

// Export
export default {
	init: init
};
