/**
 * Prins: Components / Rte / Config / Core
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// Export
export default {
	language   : 'de',
	poweredBy  : false//,
	// htmlSupport: {
	// 	// allow: [
	// 	// 	{
	// 	// 		name      : /.*/,
	// 	// 		attributes: false,
	// 	// 		styles    : false
	// 	// 	}
	// 	// ]
	// 	disallow: [
	// 		{
	// 			name: /.*/
	// 		}
	// 	],
	// 	allow: [
	// 		{
	// 			name: 'sub'
	// 		},
	// 		{
	// 			name: 'sup'
	// 		},
	// 		{
	// 			name: 'br'
	// 		}
	// 	]
	// }
};
