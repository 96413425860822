/**
 * Shared: Components > Nav
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	debounce,
	extend,
	execute,
	executeAfterTransition,
	getUid,
	noop
} from '../../utils/index';
import {isObject} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator from '../../dom/manipulator';
import EventHandler from '../../dom/event-handler';
import Data from '../../dom/data';

import BaseClass from '../../utils/base-class';

import Reveal from '../reveal';

/**
 * @type {string}
 */
const NAME = 'nav';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	buttonAttributes   : ['class', 'title'],
	closeByEsc         : true,
	closeByOutsideClick: true,
	selectorMenu       : '.nav-list',
	selectorMenuLink   : '.nav-link',
	selectorSubmenu    : '.nav-item > .nav-list, .nav-item > .nav-container',
	submenuDistance    : 16,
	labelSubmenu       : 'Submenu for %s',
	labelSubmenuTrigger: 'Show/hide submenu for %s',
	leaveOpen          : false,
	useReveal          : false,
	onInit             : noop, // (event) => { console.log('onInit', event); },
	onShow             : noop, // (event) => { console.log('onShow', event); },
	onShown            : noop, // (event) => { console.log('onShown', event); },
	onHide             : noop, // (event) => { console.log('onHide', event); },
	onHidden           : noop // (event) => { console.log('onHidden', event); }
};

/**
 *  Class
 */
class Nav extends BaseClass {
	/**
	 * @param {HTMLElement|Node} element
	 * @param {Object} [config={}]
	 */
	constructor(element, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = Nav.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this.currentOpened = null;

		this._setup();
	}

	/**
	 * Geöffnetes Untermenü schließen (API-Zugriff).
	 */
	close() {
		const openedItem = this._getOpenedItem();

		if (openedItem !== null) {
			this._toggleSubmenu(openedItem);
		}
	}

	getCurrentMenu() {
		return this._getOpenedItem();
	}

	/**
	 * Geöffnetes Untermenü schließen (API-Zugriff).
	 */
	showCurrentTree() {
		if (this.menu) {
			const buttons = SelectorEngine.find('.nav-item.-active > .nav-link', this.menu);

			for (const button of buttons) {
				const isOpened = Manipulator.getAria(button, 'expanded');
				const submenu  = Data.get(button, `${this.constructor.DATA_KEY}.menu`);

				if (!isOpened && submenu) {
					this._showSubmenu(button, submenu, false);
				}
			}
		}
	}

	// Private ---------------------------------------------------------------------------------------------------------

	/**
	 * Initialisierung von Elementen und Funktionalitäten.
	 *
	 * @private
	 */
	_setup() {
		this.menu = SelectorEngine.findOne(this._config.selectorMenu, this._element);

		// Abbrechen, wenn keine ´Nav list´ vorhanden ist.
		if (!this.menu) {
			return;
		}

		// ´No JS´-Class entfernen.
		Manipulator.removeClass(this._element, 'no-js');

		//
		// Navigations-ID prüfen. Wird benötigt!
		//

		this._id = this._element.getAttribute('id') || getUid('nav');

		this._element.setAttribute('id', this._id);

		//
		// Parsen aller ´Untermenüs´.
		//

		const submenus = SelectorEngine.find(this._config.selectorSubmenu, this.menu);

		for (const submenu of submenus) {
			const menuItem = submenu.parentElement;
			const button   = this._convertAnchor(menuItem);

			this._setupAria(submenu, button);

			if (this._config.useReveal) {
				Manipulator.addClass(submenu, '-has-reveal');
			}

			EventHandler.on(button, `click${this.constructor.EVENT_KEY}`, this._handleButtonClick.bind(this));

			Data.set(menuItem, `${this.constructor.DATA_KEY}.menuButton`, button);
			Data.set(menuItem, `${this.constructor.DATA_KEY}.menu`, submenu);
		}

		// Untermenü per ESC schließen.
		if (this._config.closeByEsc) {
			EventHandler.on(this._element, `keyup${this.constructor.EVENT_KEY}`, this._handleEsc.bind(this));
		}

		// Untermenü per Outside-Klick schließen.
		if (this._config.closeByOutsideClick) {
			EventHandler.on(document.documentElement, `click${this.constructor.EVENT_KEY}`, this._handleOutsideClick.bind(this));
		}

		// Init-Event triggern.
		const evInit = EventHandler.trigger(this._element, `init${this.constructor.EVENT_KEY}`, {
			releatedElement: this._element
		});

		execute(this._config.onInit, evInit);

		// `window.resize` anbinden.
		this._bindWindowResize();
	}

	/**
	 * Link zu einem Button konvertieren.
	 *
	 * @param {HTMLElement|Node} menuItem
	 * @returns {HTMLElement|Node|null}
	 * @private
	 */
	_convertAnchor(menuItem) {
		const anchor = SelectorEngine.children(menuItem, this._config.selectorMenuLink)[0];

		let button = null;

		if (anchor) {
			button = Manipulator.createElementFrom('<button type="button"/>');

			button.innerHTML = anchor.innerHTML.trim();

			for (const attr of this._config.buttonAttributes) {
				const attrVal = anchor.getAttribute(attr);

				if (attrVal) {
					button.setAttribute(attr, attrVal);
				}
			}

			anchor.replaceWith(button);
		}

		return button;
	}

	/**
	 * Erforderliche Aria-Attribute für den Button und die Liste integrieren.
	 *
	 * @param {HTMLElement|Node} menu
	 * @param {HTMLElement|Node} button
	 * @private
	 */
	_setupAria(menu, button) {
		const menuId = menu.getAttribute('id');

		let id = `${this._id}-${getUid('submenu')}`;

		if (menuId) {
			id = menuId;
		}

		Data.set(button, `${this.constructor.DATA_KEY}.menu`, menu);
		Data.set(button, `${this.constructor.DATA_KEY}.menuItem`, SelectorEngine.parents(button, '.nav-item')[0]);

		Manipulator.setAria(button, 'controls', id);
		Manipulator.setAria(button, 'expanded', false);

		if (this._config.labelSubmenuTrigger) {
			const s = this._config.labelSubmenuTrigger.replace(new RegExp('%s', 'gm'), button.textContent);

			Manipulator.setAria(button, 'label', s);
		}

		Manipulator.setAria(menu, 'hidden', true);
		menu.setAttribute('id', id);

		if (!Manipulator.getAria(menu, 'label') && this._config.labelSubmenu) {
			const s = this._config.labelSubmenu.replace(new RegExp('%s', 'gm'), button.textContent);

			Manipulator.setAria(menu, 'label', s);
		}
	}

	/**
	 * Prüfe auf ein zu schliessendes Untermenü vor dem öffnen eines anderen.
	 *
	 * @param {object} event
	 * @private
	 */
	_handleButtonClick(event) {
		const button = event.currentTarget;

		this._toggleSubmenu(button);
	}

	/**
	 * Untermenü(s) per ´ESC´ schließen.
	 *
	 * @param {object} event
	 * @private
	 */
	_handleEsc(event) {
		const key = event.key;
		// const openedItem = this._getOpenedItem();

		if (key === 'Escape' || key === 'esc' || parseInt(key, 10) === 27) {
			if (event.target.closest('ul[aria-hidden="false"]') !== null) {
				this.currentOpened.focus();
				this._toggleSubmenu(this.currentOpened);
			} else if (Manipulator.getAria(event.target, 'expanded')) {
				this._toggleSubmenu(this.currentOpened);
			}
		}
	}

	/**
	 * Klick außerhalb der naviagtion schließt Untermenü(s).
	 *
	 * @param {object} event
	 * @private
	 */
	_handleOutsideClick(event) {
		if (this.currentOpened && !event.target.closest('#' + this._id)) {
			this._toggleSubmenu(this.currentOpened);
		}
	}

	/**
	 * Bestimmen, ob ein Untermenü ein- oder ausgeblendet werden soll.
	 *
	 * @param {HTMLElement|Node} button
	 * @private
	 */
	_toggleSubmenu(button) {
		if (button) {
			const buttonMenu     = Data.get(button, `${this.constructor.DATA_KEY}.menu`);
			const buttonIsOpened = Manipulator.getAria(button, 'expanded');

			if (buttonIsOpened) {
				this._hideSubmenu(button, buttonMenu);
			} else {
				this._showSubmenu(button, buttonMenu);
			}
		}
	}

	/**
	 * Ausrichtung des Untermenüs kalkulieren/bestimmen.
	 *
	 * @param {HTMLElement|Node} submenu
	 * @private
	 */
	_preventOffScreenMenu(submenu = null) {
		const openedItem = this._getOpenedItem();

		let menu = submenu;

		if (
			!menu &&
			openedItem !== null
		) {
			menu = Data.get(openedItem, `${this.constructor.DATA_KEY}.menu`);
		}

		if (menu && menu.offsetParent) {
			const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			const parent      = menu.offsetParent;
			const leftEdge    = parent.getBoundingClientRect().left;
			const rightEdge   = leftEdge + menu.offsetWidth;

			if (rightEdge + this._config.submenuDistance > screenWidth) {
				Manipulator.addClass(menu, '-right');
			} else {
				Manipulator.removeClass(menu, '-right');
			}
		}
	}

	/**
	 * `window.resize`, Untermenüausrichtung, anbinden.
	 *
	 * @private
	 */
	_bindWindowResize() {
		const callback = function () {
			this._preventOffScreenMenu();
		}.bind(this);

		window.addEventListener('resize', debounce(callback));
	}

	/**
	 * Aktuellen geöffneten Menüpunkt zurückgeben.
	 *
	 * @returns {HTMLElement|Node|null}
	 * @private
	 */
	_getOpenedItem() {
		let current = null;

		if (this.currentOpened && Manipulator.getAria(this.currentOpened, 'expanded')) {
			current = this.currentOpened;
		}

		return current;
	}

	/**
	 * Menü schließen.
	 *
	 * @param {HTMLElement|Node} button
	 * @param {HTMLElement|Node} menu
	 * @param {Boolean} isWalk
	 * @private
	 */
	_hideSubmenu(button, menu, isWalk = false) {
		const evArgs   = {
			releatedElement : this._element,
			releatedMenuItem: button,
			relatedMenu     : menu
		};
		const evHide   = EventHandler.trigger(this._element, `hide${this.constructor.EVENT_KEY}`, evArgs);
		const evHidden = EventHandler.trigger(this._element, `hidden${this.constructor.EVENT_KEY}`, evArgs);

		execute(this._config.onHide, evHide);

		// Finde alle Untermenüs im Untermenü `menu`
		const childButtons = SelectorEngine.find('.nav-item > .nav-link[aria-expanded="true"]', menu);

		for (const childButton of childButtons) {
			const childButtonMenu = Data.get(childButton, `${this.constructor.DATA_KEY}.menu`);

			this._hideSubmenu(childButton, childButtonMenu, true);
		}

		if (!isWalk) {
			this.currentOpened = this._getParentActiveMenuItem(menu);
		}

		Manipulator.setAria(button, 'expanded', false);
		Manipulator.setAria(menu, 'hidden', true);

		if (this._config.useReveal) {
			const revealOptions = isObject(this._config.useReveal) ? this._config.useReveal : {};

			Reveal.hide(menu, revealOptions).then(element => {
				executeAfterTransition(
					() => {
						execute(this._config.onHidden, evHidden);
					},
					menu
				);
			});
		} else {
			executeAfterTransition(
				() => {
					execute(this._config.onHidden, evHidden);
				},
				menu
			);
		}
	}

	/**
	 * Menü öffnen.
	 *
	 * @param {HTMLElement|Node} button
	 * @param {HTMLElement|Node} menu
	 * @param {Boolean} useTransition
	 * @private
	 */
	_showSubmenu(button, menu, useTransition = true) {
		if (!this._config.leaveOpen) {
			this._closeNotAffectedMenus(button);
		}

		const evArgs  = {
			releatedElement : this._element,
			releatedMenuItem: button,
			relatedMenu     : menu
		};
		const evShow  = EventHandler.trigger(this._element, `show${this.constructor.EVENT_KEY}`, evArgs);
		const evShown = EventHandler.trigger(this._element, `shown${this.constructor.EVENT_KEY}`, evArgs);

		execute(this._config.onShow, evShow);

		Manipulator.setAria(button, 'expanded', true);
		Manipulator.setAria(menu, 'hidden', false);

		this._preventOffScreenMenu(menu);

		this.currentOpened = button;

		if (this._config.useReveal) {
			let revealOptions = isObject(this._config.useReveal) ? this._config.useReveal : {};

			if (!useTransition) {
				revealOptions = extend(
					{},
					revealOptions,
					{
						duration             : 0,
						transitionFromElement: false
					}
				);
			}

			Reveal.show(menu, revealOptions).then(element => {
				executeAfterTransition(
					() => {
						execute(this._config.onShown, evShown);
					},
					menu
				);
			});
		} else {
			executeAfterTransition(
				() => {
					execute(this._config.onShown, evShown);
				},
				menu
			);
		}
	}

	_getParentActiveMenuItem(menu) {
		let collection = SelectorEngine.parents(menu, '.nav-item');

		let parent = null;

		if (collection.length > 1) {
			collection.shift();
			collection.reverse();

			const button = SelectorEngine.children(collection[0], 'button')[0];

			if (button && Manipulator.getAria(button, 'expanded')) {
				parent = button;
			}
		}

		return parent;
	}

	_closeNotAffectedMenus(button) {
		const menuItem    = Data.get(button, `${this.constructor.DATA_KEY}.menuItem`);
		const notAffected = SelectorEngine.siblings(menuItem, '.nav-item');

		for (const item of notAffected) {
			const itemButton = Data.get(item, `${this.constructor.DATA_KEY}.menuButton`);
			const itemMenu = Data.get(item, `${this.constructor.DATA_KEY}.menu`);

			if (itemButton && itemMenu) {
				this._hideSubmenu(itemButton, itemMenu);
			}
		}
	}

	// Static ----------------------------------------------------------------------------------------------------------

	// Getters ---------------------------------------------------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default Nav;
