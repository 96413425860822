/**
 * Shared: Utils > Load
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	noop,
	extend, executeAfterTransition
} from './index';
import {isString, isObject} from "./is";
import SelectorEngine from "../dom/selector-engine";
import Manipulator from "../dom/manipulator";

// ------
// Privat
// ------

const KEY_REGEX = /[ "#$%&'()*+,./:<>?\\{}~]/g;

const DEFAULTS = {
	css: {
		id    : '',
		target: document.head
	},
	js : {
		async : true,
		defer : false,
		id    : '',
		target: document.body
	}
};

const loadedFiles = new Map();

/**
 * Script- oder Style-Identifier generieren
 *
 * @param {String} path
 * @returns {String}
 */
const generatePathIdentifier = (path) => {
	const parts = path.split('.');
	const type  = parts.pop();
	const key = parts[0].split('/').map((s) => s.slice(0,3));

	return `${key.join('')}-${type}`;
};

/**
 * Style-Tag generieren
 *
 * @param {Object} file
 * @returns {Promise}
 */
const createCss = (file) => {
	return new Promise(function (resolve, reject) {
		if (file.id === undefined || file.id === '') {
			file.id = generatePathIdentifier(file.src);
		}

		if (loadedFiles.has(file.id)) {
			resolve(file);
		} else {
			let element = document.createElement('link');

			element.id  = file.id;
			element.rel  = 'stylesheet';
			element.type = 'text/css';
			element.href = file.src;

			element.addEventListener('error', () => {
				if (loadedFiles.has(file.id)) {
					loadedFiles.delete(file.id);
				}

				reject(file);
			});

			element.addEventListener('load', () => {
				loadedFiles.set(file.id, file);

				resolve(file);
			});

			file.target.append(element);
		}
	});
};

/**
 * Script-Tag generieren
 *
 * @param {Object} file
 * @returns {Promise}
 */
const createJs = (file) => {
	return new Promise(function (resolve, reject) {
		if (file.id === undefined || file.id === '') {
			file.id = generatePathIdentifier(file.src);
		}

		if (loadedFiles.has(file.id)) {
			resolve(file);
		} else {
			let element = document.createElement('script');

			element.id  = file.id;
			element.src = file.src;

			if (file.async) {
				element.async = true;
			}

			if (file.defer) {
				element.defer = true;
			}

			// element.rel  = 'stylesheet';
			// element.type = 'text/css';

			element.addEventListener('error', () => {
				if (loadedFiles.has(file.id)) {
					loadedFiles.delete(file.id);
				}

				reject(file);
			});

			element.addEventListener('load', () => {
				loadedFiles.set(file.id, file);

				resolve(file);
			});

			file.target.append(element);
		}
	});
};

// ------
// Public
// ------

/**
 * Script- oder Style-Tags integrieren.
 *
 * @param {String|Object|Array} files
 * @returns {Promise}
 */
const load = async (files) => {
	let promiseData = [];
	let collection  = [];

	if (files) {
		collection = isString(files) || isObject(files) ? [files] : files;
	}

	for (const item of collection) {
		let filetype;
		let file;

		if (isObject(item) && item.src !== undefined) {
			filetype = item.src.split('.').pop();
			file = item;
		} else {
			filetype = item.split('.').pop();
			file = {
				src: item
			};
		}

		if (['css', 'js'].includes(filetype.toLowerCase())) {
			const data = extend({}, DEFAULTS[filetype], file, {
				type: filetype
			});

			if (filetype === 'js') {
				promiseData.push(createJs(data));
			} else {
				promiseData.push(createCss(data));
			}
		}
	}

	return Promise.all(promiseData).then(function(data) {
		return data;
		// Required files are loaded successfully
	}).catch(function (error) {
		throw new Error(error);
	});
};

/**
 * Ein - vorher - integriertes Script- oder Style-Tag anhand der ID entfernen.
 *
 * @param {String} key
 * @returns {Promise}
 */
const unload = (key) => {
	return new Promise((resolve, reject) => {
		try {
			const data    = loadedFiles.has(key) ? loadedFiles.get(key) : null;
			const element = (data) ? SelectorEngine.findOne(`#${data.id}`) : null;

			if (element) {
				element.remove();

				resolve(key);
			}
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Script in Dom integrieren
 *
 * @deprecated PLease use `load` instead
 * @param {String} src
 * @param {Object} [o={}]
 */
const loadJavascript = (src, o = {}) => {
	if (src) {
		load(
			extend({}, DEFAULTS.js, {
				src: src
			}, o)
		).then((data) => {
			if (typeof o.callback === 'function') {
				o.callback(data);
			}
		});
	}
};

/**
 * Script aus Dom entfernen
 *
 * @deprecated PLease use `unload` instead
 * @param {String} s
 * @param {Function} callback
 */
const unloadJavascript = (s, callback = noop) => {
	unload(s).then((data) => {
		if (typeof callback === 'function') {
			callback(data);
		}
	});
};

/**
 * Style in Dom integrieren
 *
 * @deprecated PLease use `load` instead
 *
 * @param {String} src
 * @param {Object} [o={}]
 */
const loadStyle = (src, o = {}) => {
	if (src) {
		load(
			extend({}, DEFAULTS.css, {
				src: src
			}, o)
		).then((data) => {
			if (typeof o.callback === 'function') {
				o.callback(data);
			}
		});
	}
};

/**
 * Style aus Dom entfernen
 *
 * @deprecated PLease use `unload` instead
 * @param {String} s
 * @param {Function} callback
 */
const unloadStyle = (s, callback = noop) => {
	unload(s).then((data) => {
		if (typeof callback === 'function') {
			callback(data);
		}
	});
};

// Export
export {
	load,
	unload,
	loadJavascript,
	unloadJavascript,
	loadStyle,
	unloadStyle
};
