/**
 * Prins: Components > Manifest
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/**
 * Components
 */

// import Data           from '../../shared/js/dom/data';
import SelectorEngine from '../../shared/dom/selector-engine';
import EventHandler   from '../../shared/dom/event-handler';

import Autocomplete from './custom/autocomplete';
import Notifications from './custom/notifications';
import Button from '../../shared/components/button';
import Card from './overwrites/shared/card';
import Details from '../../shared/components/details';
// import List           from '../../shared/components/list';
// import Player         from '../../shared/components/player';
// import Slider         from '../../shared/components/slider';
import Drawer from './overwrites/shared/drawer';
// import ScrollProgress from '../../shared/components/scroll-progress';
import TabGroup from '../../shared/components/tab-group';
// import MediaGallery   from '../../shared/components/media-gallery';
import Form from './overwrites/shared/form';
import Choices from './custom/choices';
import DateTimePicker from "./custom/date-time-picker";
import FilterList from './custom/filter-list';
import MultiSelect from './custom/multi-select';
import SwitchView from './custom/switch-view';
import Rte from './custom/rte';
import Modal from './custom/modal';
import UploadPreview from './custom/upload-preview';
import Sortable from './custom/sortable';
// import AutoValue from "./overwrites/shared/form/tools/auto-value";
// import GMap           from '../../shared/components/gmap';
// import Jumplist       from '../../shared/components/jumplist';
import Tag            from '../../shared/components/tag';
// import ProgressBar    from '../../shared/components/progress-bar';
// import ProgressRing   from '../../shared/components/progress-ring';
import RevealPassword from '../../shared/components/reveal-password';
import NestedRecords from './custom/nested-records';

// JS-Snippets die vorerst nicht sortierbar sind.
import Snippets from './custom/snippets';

// ------
// Public
// ------

const components = () => {
	Notifications.init();
	Modal.init();
	Button.init();
	Card.init();
	// List.init();
	Details.init();
	// Slider.init();
	TabGroup.init();
	// Player.init();
	// MediaGallery.init();
	Drawer.init();
	// Jumplist.init();
	// ScrollProgress.init();

	Form.init();
	Choices.init();
	DateTimePicker.init();
	FilterList.init();
	MultiSelect.init();
	SwitchView.init();
	Rte.init();
	Tag.init(null, {
		removeCtrlLabel: window.Prins.tm.translate('remove')
	});
	RevealPassword.init();
	Autocomplete.init();

	//
	// Upload preview initialisieren.
	//

	const collectionFile = SelectorEngine.find('[data-upload-preview]');

	if (collectionFile.length > 0) {
		for (const element of collectionFile) {
			new UploadPreview(element, {
				preview : {
					alt: (window.Prins.tm) ? window.Prins.tm.translate('preview') : 'Preview',
				},
				add     : {
					label: (window.Prins.tm) ? window.Prins.tm.translate('selectFile') : 'Select file',
					text : (window.Prins.tm) ? window.Prins.tm.translate('selectFile') : 'Select file'
				},
				remove  : {
					label: (window.Prins.tm) ? window.Prins.tm.translate('removeFile') : 'Remove file',
					text : (window.Prins.tm) ? window.Prins.tm.translate('removeFile') : 'Remove file'
				},
				onRemove: (event) => {
					// Formularänderung dem Elternformular bekannt geben!
					const form = SelectorEngine.parents(event.element, 'form')[0];

					if (form) {
						EventHandler.trigger(form, 'changeDetected');
					}
				}
			});
		}
	}

	// ProgressBar.init();
	// ProgressRing.init();
	// RevealPassword.init();
	// Reveal.init();

	// TODO (JS), Sortierung ´Drag'n'Drop´ aktivieren.
	Sortable.init();

	NestedRecords.init();

	// JS-Snippets die vorerst nicht sortierbar sind.
	Snippets();

	// const form = SelectorEngine.findOne('#exampleallfields-126');
	//
	// if (form) {
	// 	EventHandler.on(form, 'init.ifab.nfv', event => {
	// 		console.log('EV:init.ifab.nfv', event);
	// 	});
	// 	EventHandler.on(form, 'validationFailed.ifab', event => {
	// 		console.log('EV:validationFailed.ifab', event);
	// 	});
	// 	EventHandler.on(form, 'validationSuccess.ifab', event => {
	// 		console.log('EV:validationSuccess.ifab', event);
	// 	});
	//
	// 	Form.nativeFormValidation(form, {
	// 		onInit   : (event) => {
	// 			console.log('OPT:onInit', event);
	// 		}, // noop,
	// 		onValidationFailed: (event) => {
	// 			console.log('OPT:onValidationFailed', event);
	// 		}, // noop,
	// 		onValidationSuccess  : (event) => {
	// 			console.log('OPT:onValidationSuccess', event);
	// 		} // noop
	// 	});
	// }

	// const gMapAllowed = (typeof window.ifabCookieConsent !== 'undefined') ? window.ifabCookieConsent.getConsent('googlemaps') : true;
	//
	// if (gMapAllowed && typeof window.pageInstance.settings.maps.google.id) {
	// 	GMap.init(null, {
	// 		apiKey  : window.pageInstance.settings.maps.google.id,
	// 		settings: {
	// 			// Siehe google-maps.js -> DEFAULTS
	// 			map: {
	// 				zoom: 14
	// 			}
	// 		}
	// 	});
	// }
};

// Export
export default components;
