/**
 * Prins: Components / Switch view
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'switch-view';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const getViews = (key, controls, target) => {
	const currentStorageView = localStorage.getItem(`${window.Prins.prefix}switch-view-${key}`);

	let data = {
		current: '',
		views  : {}
	};

	let currentControlView = '';

	// Verfügbare ´Views´ von den Buttons auslesen.
	for (const control of controls) {
		data.views[control.dataset.view] = `-view-${control.dataset.view}`;

		if (Manipulator.getAria(control, 'pressed') === true) {
			currentControlView = control.dataset.view;
		}
	}

	const viewKeys    = Object.keys(data.views);
	const viewClasses = Object.values(data.views);

	if (currentStorageView && viewKeys.indexOf(currentStorageView) >= 0) {
		data.current = currentStorageView;
	} else if (currentControlView && viewKeys.indexOf(currentControlView) >= 0) {
		data.current = currentControlView;
	} else {
		for (let c in viewClasses) {
			if (!data.current && target.classList.contains(c)) {
				data.current = currentControlView;
			}
		}

		if (!data.current) {
			data.current = viewKeys[0];
		}
	}

	return data;
};

// ------
// Public
// ------

const render = (element) => {
	// Wurde Container schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const key      = Manipulator.getDataAttribute(element, `${NAME}`);
	const target   = (key) ? SelectorEngine.findOne(`#${key}`) : null;
	const controls = SelectorEngine.find('[data-view]', element);

	if (target && controls.length > 0) {
		const childs      = SelectorEngine.children(target, '*');
		const data        = getViews(key, controls, target);
		// const viewKeys    = Object.keys(data.views);
		const viewClasses = Object.values(data.views);

		// Handling ´switch view´.
		EventHandler.on(element, `switchView${EVENT_KEY}`, (event) => {
			const classRemove = viewClasses.join(' ');
			const classAdd    = data.views[event.view];

			for (const control of controls) {
				if (control.dataset.view === event.view) {
					Manipulator.addClass(control, '_active');
					Manipulator.setAria(control, 'pressed', true);
				} else {
					Manipulator.removeClass(control, '_active');
					Manipulator.setAria(control, 'pressed', false);
				}
			}

			Manipulator.removeClass(target, classRemove);
			Manipulator.addClass(target, classAdd);

			for (const child of childs) {
				Manipulator.removeClass(child, classRemove);
				Manipulator.addClass(child, classAdd);
			}

 			localStorage.setItem(`${window.Prins.prefix}switch-view-${key}`, event.view);
		});

		// Buttonclick.
		EventHandler.on(element, `click${EVENT_KEY}`, '[data-view]', (event) => {
			event.preventDefault();

			if (!event.delegateTarget.classList.contains('_active')) {
				// EventHandler.trigger
				EventHandler.trigger(element, `switchView${EVENT_KEY}`, {
					view: event.delegateTarget.dataset.view
				});
			}
		});

		// Initialer Switch.
		EventHandler.trigger(element, `switchView${EVENT_KEY}`, {
			view: data.current
		});
	}

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

/**
 * ´Record group´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {
		group.push(render(element));
	}

	return group;
};

// Export
export default {
	init: init
};
