/**
 * Prins: Components / Date and time picker
 * (Dependency: https://flatpickr.js.org/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	extend,
	normalizeAttributeValue
} from '../../../../shared/utils/index';
import {isElement, isString} from "../../../../shared/utils/is";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';
import Data from '../../../../shared/dom/data';

// -------
// Private
// -------

const NAME      = 'datetimepicker';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {
	time_24hr: true,
	onReady  : (a, b, instance) => {
		// Correcting the plugin misbehavior regarding dom manipulation (label `for` > alement `id`).
		if (instance.altInput) {
			const id    = instance.element.getAttribute('id');
			const label = SelectorEngine.findOne(`label[for="${id}"]`);

			if (label) {
				label.setAttribute('for', `${id}-label-fixed`);

				instance.altInput.setAttribute('id', `${id}-label-fixed`);
			}
		}

		if (instance.calendarContainer) {
			Manipulator.addClass(instance.calendarContainer, `-view-${instance.element.dataset.datepickerView}`);

			//
			// Kalenderfuß (now, reset) integrieren
			//

			const calFooter = Manipulator.elementAppend(`<div class="flatpickr-footer"/>`, instance.calendarContainer);

			const iconNow = (instance.config.datepickerView === 'time') ? window.Prins.config.icons.clock : window.Prins.config.icons.calendarCheck;
			const ctrlNow = Manipulator.elementAppend(`<button class="icon-button -default -s" type="button" title="${window.Prins.tm.translate('now')}">
				<span class="icon-button__icon">${iconNow}</span>
			</button>`, calFooter);

			EventHandler.on(ctrlNow, `click${EVENT_KEY}`, (event) => {
				event.preventDefault();

				instance.setDate(Date.now());

				EventHandler.trigger(instance.element, `now${EVENT_KEY}`, {
					instance
				});
			});

			const ctrlReset = Manipulator.elementAppend(`<button class="icon-button -default -s" type="button" title="${window.Prins.tm.translate('reset')}">
				<span class="icon-button__icon">${window.Prins.config.icons.remove}</span>
			</button>`, calFooter);

			EventHandler.on(ctrlReset, `click${EVENT_KEY}`, (event) => {
				event.preventDefault();

				instance.clear();

				EventHandler.trigger(instance.element, `reset${EVENT_KEY}`, {
					instance
				});
			});
		}

		EventHandler.trigger(instance.element, `ready${EVENT_KEY}`, {
			instance
		});
	},
	onChange : function (selectedDates, dateStr, instance) {
		EventHandler.trigger(instance.element, `change${EVENT_KEY}`, {
			selectedDates,
			dateStr,
			instance
		});
	},
	onOpen   : function (selectedDates, dateStr, instance) {
		EventHandler.trigger(instance.element, `open${EVENT_KEY}`, {
			selectedDates,
			dateStr,
			instance
		});
	},
	onClose  : function (selectedDates, dateStr, instance) {
		EventHandler.trigger(instance.element, `close${EVENT_KEY}`, {
			selectedDates,
			dateStr,
			instance
		});
	}
};

const observerInView = new IntersectionObserver((entries) => {
	for (const element of entries) {
		if (element.isIntersecting) {
			EventHandler.trigger(element.target,`init${EVENT_KEY}`);
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

/**
 * ´Zusammenstellen der Datumsparameter zu einem Eingabefeld.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {Object}
 */
const getDateParams = (element, o) => {
	let params = {
		altFormat : normalizeAttributeValue(element.dataset.altFormat ?? window.Prins.tm.translate('formatDate')),
		altInput  : true,
		dateFormat: normalizeAttributeValue(element.dataset.dateFormat ?? 'Y-m-d'),
		enableTime: false
	};

	const enableTime = element.type === 'datetime-local' || normalizeAttributeValue(element.dataset.enableTime);

	// Zeit aktivieren?
	if (enableTime === true) {
		params.enableTime = true;
		params.altFormat  = `${params.altFormat} ${window.Prins.tm.translate('formatTime')}`;
		params.dateFormat = `${params.dateFormat}T${window.Prins.tm.translate('formatTime')}`;

		if (element.dataset.timeHrs !== undefined) {
			params.time_24hr = (element.dataset.timeHrs !== 'false');
		}
	}

	// Multiple.
	if (normalizeAttributeValue(element.dataset.multipleDate ?? false)) {
		params.mode = 'multiple';
	}

	// Minimum date
	if (normalizeAttributeValue(element.dataset.minDate)) {
		params.minDate = normalizeAttributeValue(element.dataset.minDate);
	}

	// Maximum date
	if (normalizeAttributeValue(element.dataset.maxDate)) {
		params.maxDate = normalizeAttributeValue(element.dataset.maxDate);
	}

	// Range
	if (normalizeAttributeValue(element.dataset.range ?? false)) {
		params.mode = 'range';
	}

	// Eine Einschränkung auf auswahlbäre Tage funktioniert nicht in verbindung mit Zeit!
	if (!enableTime) {
		// Enable date
		if (normalizeAttributeValue(element.dataset.enableDate)) {
			const vED = normalizeAttributeValue(element.dataset.enableDate);

			params.enable = vED.split(',');
		} else if (normalizeAttributeValue(element.dataset.disableDate)) {
			// Disable date
			const vDD = normalizeAttributeValue(element.dataset.disableDate);

			params.disable = vDD.split(',');
		}
	}

	// Wochennummern.
	const weekNumbers = normalizeAttributeValue(element.dataset.weekNumbers);

	if (weekNumbers !== null && weekNumbers !== '') {
		params.weekNumbers = weekNumbers;
	}

	return extend({}, o, params);
};

/**
 * Zusammenstellen der Zeitparameter zu einem Eingabefeld.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {Object}
 */
const getTimeParams = (element, o) => {
	let params = {
		defaultHour: 8,
		noCalendar : true,
		enableTime : true,
		dateFormat : normalizeAttributeValue(element.dataset.timeFormat) ?? window.Prins.tm.translate('formatTime')
	};

	if (element.dataset.timeHrs !== undefined) {
		params.time_24hr = (element.dataset.timeHrs !== 'false');
	}

	if (element.dataset.defaultHour !== undefined) {
		params.defaultHour = normalizeAttributeValue(element.dataset.defaultHour);
	}

	if (element.dataset.defaultMinute !== undefined) {
		params.defaultMinute = normalizeAttributeValue(element.dataset.defaultMinute);
	}

	if (element.dataset.defaultSeconds !== undefined) {
		params.defaultMinute = normalizeAttributeValue(element.dataset.defaultSeconds);
	}

	if (normalizeAttributeValue(element.dataset.defaultTime)) {
		params.defaultDate = normalizeAttributeValue(element.dataset.defaultTime);
	}

	// Minimum time
	if (normalizeAttributeValue(element.dataset.minTime)) {
		params.minTime = normalizeAttributeValue(element.dataset.minTime);

		params.defaultHour = params.minTime.split(':')[0];
		params.defaultminute = params.minTime.split(':')[1];
	}

	// Maximum time
	if (normalizeAttributeValue(element.dataset.minTime)) {
		params.maxTime = normalizeAttributeValue(element.dataset.maxTime);
	}

	return extend({}, o, params);
};

/**
 * ´Date select´-Container initialisieren.
 *
 * @param {HTMLElement} element
 * @param {String} [type=date]
 * @param {Object} [o={}]
 * @returns {HTMLElement}
 */
const render = (element, type = 'date', o = {}) => {
	// Wurde Container schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const params   = type === 'time' ? getTimeParams(element, o) : getDateParams(element, o);
	const instance = flatpickr(element, params);

	Data.set(element, `${DATA_KEY}.instance`, instance);

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// ------
// Public
// ------

/**
 * ´Date-, Time´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group      = [];
	let collection = [];

	// Prüfe Abhängigkeit.
	if (window.flatpickr === undefined) {
		if (window.Prins.error) {
			window.Prins.error.show({
				msg: "The dependency ´flatpickr´ cannot be loaded/initialized"
			});
		} else {
			throw new Error('The dependency ´flatpickr´ cannot be loaded/initialized');
		}

		return group;
	}

	// Stelle Element zusammen.
	if (isElement(m)) {
		collection.push(m);
	} else {
		const selector = 'input[type="date"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="time"], input[data-provider="datepicker"], input[data-provider="timepicker"]';

		collection = SelectorEngine.find(
			(isString(m)) ? m : selector,
			_o.container || document.documentElement
		);
	}

	// Elemente initialisieren.
	if (collection.length > 0) {
		const lang = window.Prins.tm.getLang() ?? 'de';

		flatpickr.localize(flatpickr.l10ns[lang]);
		flatpickr.l10ns.default.firstDayOfWeek = (lang === 'de') ? 1 : 7;

		for (const element of collection) {
			if (
				['date', 'datetime-local', 'month', 'week'].indexOf(element.type) > -1 ||
				element.dataset.provider === 'datepicker'
			) {
				element.dataset.datepickerView = 'date';

				EventHandler.one(element,`init${EVENT_KEY}`, () => {
					group.push(render(element, 'date', _o));
				});
			} else if(['time'].indexOf(element.type) > -1 || element.dataset.provider === 'timepicker') {
				element.dataset.datepickerView = 'time';

				EventHandler.one(element,`init${EVENT_KEY}`, () => {
					group.push(render(element, 'time', _o));
				});
			}

			// Element überwachen.
			observerInView.observe(element);
		}
	}

	return group;
};

// Export
export default {
	init: init
};
