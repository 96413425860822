/**
 * Prins: Components / Autocomplete
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

// eslint-disable-next-line import/no-unresolved
import Autocomplete from '../../../../../../node_modules/bootstrap5-autocomplete/autocomplete';

import {noop, extend} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

import ClearableField from '../../../../shared/components/form/tools/clearable-field';

// -------
// Private
// -------

const NAME      = 'autocomplete';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {
	liveServer          : '',
	queryParam          : 'q',
	ignoreEnter         : true,
	serverMethod        : 'post',
	suggestionsThreshold: 2,
	onSelectItem        : noop // (data, field)
};

/**
 * Abhängigkeit von ´autoComplete´ (node_modules/bootstrap5-autocomplete/autocomplete.min.js) prüfen.
 * @returns {boolean}
 *
const checkDependency = () => {
	if (!window.Prins.autocomplete.depCheck) {
		if (window.Autocomplete === undefined) {
			const msg = 'The dependency ´Autocomplete´ is missing';

			if (window.Prins.notificationDanger) {
				window.Prins.notificationDanger(msg, {
					duration: -1
				});
			} else {
				throw new Error(msg);
			}

			window.Prins.autocomplete.depStatus = false;
		} else {
			window.Prins.autocomplete.depStatus = true;
		}

		window.Prins.autocomplete.depCheck = true;
	}

	return window.Prins.autocomplete.depStatus;
}; */

/**
 * ´Autocomplete´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {HTMLElement}
 */
const render = (element, o = {}) => {
	// if (!checkDependency()) {
	// 	return element;
	// }

	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	// Wrapper integrieren?
	if (element.parentNode.classList.contains('input-group')) {
		Manipulator.addClass(element.parentNode, 'autocomplete-attached');
	} else {
		const wrapper = Manipulator.elementAfter('<div class="autocomplete"/>', element);

		Manipulator.elementAppend(element, wrapper);
	}

	// Aktuelle Optionen.
	const _o = extend({}, DEFAULTS, o);

	new Autocomplete(element, {
		activeClasses       : [],
		liveServer          : true,
		highlightClass      : '',
		highlightTyped      : true,
		notFoundMessage     : '',
		queryParam          : (element.dataset.queryParam ?? _o.queryParam),
		serverMethod        : (element.dataset.serverMethod ?? _o.serverMethod),
		suggestionsThreshold: parseInt((element.dataset.suggestionsThreshold ?? _o.suggestionsThreshold), 10),
		// Event beim Selektieren eines Eintrages.
		onSelectItem: (data, inst) => {
			_o.onSelectItem(data, inst._searchInput);

			EventHandler.trigger(element, `selectItem${EVENT_KEY}`, {
				data
			});
		},
		// Event beim Rendering eines Eintrages.
		onRenderItem: (item, label) => {
			const res   = [];
			const icon  = item.icon ?? '';
			const image = item.image ?? '';

			if (icon) {
				res.push(`<span class="dropdown-item__prefix"><i aria-hidden="true" class="${icon}"></i></span>`);
			} else if (image) {
				res.push(`<span class="dropdown-item__prefix"><img alt="Icon" src="${image}"/></span>`);
			}

			res.push(`<span class="dropdown-item__label">${label}</span>`);

			return res.join('');
		}
	});

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	// Attribute ´data-clearable´ gesetzt?
	if (element.dataset.clearable !== undefined) {
		new ClearableField(element);
	}

	return element;
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @param {Object} [o={}]
 * @returns {Array}
 */
const init = (o = {}) => {
	window.Prins.autocomplete = window.Prins.autocomplete ?? {
		depCheck : false,
		depStatus: false
	};

	const collection = SelectorEngine.find(`[data-provider="${NAME}"]`);

	let group = [];

	if (collection.length) {
		for (const element of collection) {
			group.push(render(element, o));
		}
	}

	return group;
};

// ------
// Public
// ------

// Export
export default {
	init: init
};
